import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../../styles/ProductList.css';
import Navbar from '../Navbar/Navbar';
import { useAuth } from '../../context/AuthContext';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';

const ProductList = ({ showRequestButton = true, showNavbar = true, showAddToCartButton, onAddToCart }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { refreshToken } = useAuth();

  const fetchProducts = async (token) => {
    console.log('Fetching products with token:', token); // Log the token being used
    try {
      const response = await axios.get(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.PRODUCTLIST_URL}/list`,
        { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
      );
      setProducts(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching products:', error);
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        // Token may be expired or invalid
        throw new Error('Unauthorized');
      } else {
        setError('Error fetching products. Please try again later.');
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      const refreshTokenValue = localStorage.getItem('refreshToken');

      console.log('Initial token:', token);
      console.log('Refresh token:', refreshTokenValue);

      if (token) {
        try {
          await fetchProducts(token);
        } catch (error) {
          if (error.message === 'Unauthorized' && refreshTokenValue) {
            try {
              console.log('Attempting to refresh token with refresh token:', refreshTokenValue);
              const newTokens = await refreshToken(refreshTokenValue);
              console.log('Tokens refreshed:', newTokens);
              localStorage.setItem('token', newTokens.access_token);
              localStorage.setItem('refreshToken', newTokens.refresh_token);
              await fetchProducts(newTokens.access_token);
            } catch (refreshError) {
              console.error('Refresh token failed:', refreshError);
              setError('Your session has expired. Please log in again.');
              navigate('/login', { state: { message: 'Your session has expired. Please log in again.' } });
            }
          } else {
            setError('Your session has expired. Please log in again.');
            navigate('/login', { state: { message: 'Your session has expired. Please log in again.' } });
          }
        }
      } else {
        setError('No access token found. Please log in.');
        setLoading(false);
        navigate('/login', { state: { message: 'No access token found. Please log in.' } });
      }
    };

    fetchData();
  }, [refreshToken, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleRequest = (productId, productName) => {
    console.log(`Request raised for product ID: ${productId}`);
    navigate('/servicerequest', { state: { productName } });
  };

  const handleAddToCartClick = (productName) => {
    onAddToCart(productName);
  };

  return (
    <div>
      {showNavbar && <Navbar />}
      <div className="product-list">
        {products.map((product) => (
          <div key={product.id} className="product-item">
            <Link to={`/products/${product.id}`} className="product-link">
              <img src={ApiEndpoints.BASEURL_IMAGE + product.thumbnail} alt={product.name} className="product-thumbnail" />
            </Link>
            <div className="product-description">
              <div className="product-description-content">
                <h3>{product.name}</h3>
                <div dangerouslySetInnerHTML={{ __html: product.description }} />
                <p>${product.price}</p>
              </div>
              {showAddToCartButton && (
                <div className="product-add-to-cart-button">
                  <button
                    className="add-to-cart-button"
                    onClick={() => handleAddToCartClick(product.name)}
                  >
                    Add to Cart
                  </button>
                </div>
              )}
              {showRequestButton && (
                <div className="product-request-button">
                  <button
                    className="request-button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent click event from propagating to parent Link
                      handleRequest(product.id, product.name);
                    }}
                  >
                    Request
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
