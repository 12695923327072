import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import '../../styles/CustomerList.css'; // Adjust path to CSS file as needed
import Navbar from '../Navbar/Navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editCustomerData, setEditCustomerData] = useState({});
  const [newCustomerData, setNewCustomerData] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    password: '',
    role: 'CUSTOMER'
  });

  // Function to fetch customers
  const fetchCustomers = async () => {
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }
      console.log('Access Token:', access_token);

      const response = await axios.get(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}?user_type=CUSTOMER`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );

      if (Array.isArray(response.data)) {
        setCustomers(response.data);
      } else {
        console.error('Unexpected data format:', response.data);
        setCustomers([]);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError('Error fetching customers. Please try again later.');
      setLoading(false);
    }
  };

  // Fetch customers on component mount
  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleEditClick = (customer) => {
    setEditCustomerData(customer);
    setShowEditPopup(true);
  };

  const handleEditCustomer = async (event) => {
    event.preventDefault();

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      console.log('Access Token for Edit:', access_token);
      console.log('Edit Customer Data:', editCustomerData);

      const response = await axios.put(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}/${editCustomerData.id}`,
        editCustomerData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        const updatedCustomers = customers.map(cust =>
          cust.id === editCustomerData.id ? editCustomerData : cust
        );
        setCustomers(updatedCustomers);
        setShowEditPopup(false);
        toast.success('Employee edited successfully!');
      } else {
        console.error('Failed to edit customer:', response);
      }
    } catch (error) {
      console.error('Error editing customer:', error);
      setError('Error editing customer. Please try again later.');
    }
  };

  const handleDeleteClick = (customer) => {
    setCustomerToDelete(customer);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setShowModal(false);

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        console.error('No token found');
        throw new Error('No token found');
      }

      console.log('Attempting to delete customer with ID:', customerToDelete.id);

      const response = await axios.delete(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}/${customerToDelete.id}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );

      console.log('Delete request response:', response);

      if (response.status === 200) {
        console.log('Customer deleted successfully:', customerToDelete.id);
        toast.success('Customer Deleted successfully!');
        const updatedCustomers = customers.filter(cust => cust.id !== customerToDelete.id);
        setCustomers(updatedCustomers);
      } else {
        console.error('Failed to delete customer:', response);
        throw new Error('Failed to delete customer');
      }
    } catch (error) {
      console.error('Error deleting customer:', error);
      setError('Error deleting customer. Please try again later.');
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
  };

  const handleAddCustomer = async (event) => {
    event.preventDefault();

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }
      console.log('Access Token for Add:', access_token);
      console.log('New Customer Data:', newCustomerData);

      const headers = {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'application/json'
      };
      console.log('Request Headers:', headers);

      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}`,
        newCustomerData,
        { headers }
      );

      if (response.status === 200) {
        console.log(response.data.message);
        setShowAddPopup(false);
        toast.success('Customer added successfully!');
        fetchCustomers();
      } else {
        console.error('Failed to add customer:', response.data);
        toast.error('Failed to add customer. Please try again.');
      }
    } catch (error) {
      console.error('Error adding customer:', error);
      setError('Error adding customer. Please try again later.');
      toast.error('Error adding customer. Please try again.');
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCustomers = customers.filter(
    (customer) =>
      customer && customer.name && customer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddCustomerClick = () => {
    setNewCustomerData({
      name: '',
      email: '',
      mobileNumber: '',
      password: '',
      role: 'CUSTOMER'
    });
    setShowAddPopup(true);
  };

  const handleClosePopup = () => {
    setShowAddPopup(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <ToastContainer />
      <Navbar />

      <div className="customer-list-container">
        <div className="customer-list-actions">
          <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input06"
          />
          <h1>Customer List</h1>
          <button className="add-customer-button" onClick={handleAddCustomerClick}>Add Customer</button>
        </div>
        <div className="table-responsive">
        <table className="customer-list-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile number</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomers.map((customer) => (
              <tr key={customer.id}>
                <td data-label="ID">{customer.id}</td>
                <td data-label="Name">{customer.name}</td>
                <td data-label="Email">{customer.email}</td>
                <td data-label="Mobile number">{customer.mobileNumber}</td>
                <td data-label="Role">{customer.role}</td>
                <td data-label="Actions">
                  <div className="action-buttons-container">
                    <button className="edit-button19" onClick={() => handleEditClick(customer)}>Edit</button>
                    <button className="delete-button19" onClick={() => handleDeleteClick(customer)}>Delete</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>

        {/* Add Customer Popup */}
        {showAddPopup && (
          <div className="customer-popup">
            <div className="customer-popup-content">
              <h2>Add Customer</h2>
              <form onSubmit={handleAddCustomer}>

                <div className="form-group-cust">
                  <label>Name:</label>
                  <input
                    type="text"
                    value={newCustomerData.name}
                    onChange={(e) => setNewCustomerData({ ...newCustomerData, name: e.target.value })}
                    required
                  />
                </div>

                <div className="form-group-cust">
                  <label>Email:</label>
                  <input
                    type="email"
                    value={newCustomerData.email}
                    onChange={(e) => setNewCustomerData({ ...newCustomerData, email: e.target.value })}
                    required
                  />
                </div>

                <div className="form-group-cust">
                  <label>Mobile number:</label>
                  <input
                    type="text"
                    value={newCustomerData.mobileNumber}
                    onChange={(e) => setNewCustomerData({ ...newCustomerData, mobileNumber: e.target.value })}
                    required
                    pattern="\d{10}"
                    maxLength="10"
                  />
                </div>

                <div className="form-group-cust">
                  <label>Password:</label>
                  <input
                    type="password"
                    value={newCustomerData.password}
                    onChange={(e) => setNewCustomerData({ ...newCustomerData, password: e.target.value })}
                    required
                    pattern=".{8,}"
                    title="Password must be at least 8 characters long."
                  />
                </div>

                <div className="button-container35">
                  <button type="button" className="cancel-button35" onClick={handleClosePopup}>Cancel</button>
                  <button type="submits" className="save-button35">Add Customer</button>
                  <button className="close-button35" onClick={handleClosePopup}>Close</button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Edit Customer Popup */}
        {showEditPopup && (
          <div className="customer-popup">
            <div className="customer-popup-content">
              <h2>Edit Customer</h2>
              <form onSubmit={handleEditCustomer}>

                <div className="form-group-cust">
                  <label>Name:</label>
                  <input
                    type="text"
                    value={editCustomerData.name}
                    onChange={(e) => setEditCustomerData({ ...editCustomerData, name: e.target.value })}
                    required
                  />
                </div>

                <div className="form-group-cust">
                  <label>Email:</label>
                  <input
                    type="email"
                    value={editCustomerData.email}
                    onChange={(e) => setEditCustomerData({ ...editCustomerData, email: e.target.value })}
                    required
                  />
                </div>

                <div className="form-group-cust">
                  <label>Mobile number:</label>
                  <input
                    type="text"
                    value={editCustomerData.mobileNumber}
                    onChange={(e) => setEditCustomerData({ ...editCustomerData, mobileNumber: e.target.value })}
                    required
                    pattern="\d{10}"
                    maxLength="10"
                    title="Please enter a valid 10-digit mobile number."
                  />
                </div>
                {/* <label>Password:</label>
                <input
                  type="password"
                  value={editCustomerData.password}
                  onChange={(e) => setEditCustomerData({ ...editCustomerData, password: e.target.value })}
                  required
                  pattern=".{8,}"
                  title="Password must be at least 8 characters long."
                /> */}
                <div className="button-container35">
                  <button type="button" className="cancel-button35" onClick={() => setShowEditPopup(false)}>Cancel</button>
                  <button type="submits" className="save-button35">Save Changes</button>
                  <button className="close-button35" onClick={() => setShowEditPopup(false)}>Close</button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Delete Confirmation Modal */}
        {showModal && (
          <div className="modal-overlayb">
            <div className="modal-contentb">
              <p>Are you sure you want to delete the {customerToDelete.name}?</p>
              <div className="button-containerb">
                <button className='Confirm' onClick={handleConfirmDelete}>Yes</button>
                <button className='Cancel' onClick={handleCancelDelete}>No</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerList;
