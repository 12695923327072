// src/pages/UserPage/UserPage.js
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import Navbar from '../Navbar/Navbar';
 import '../../styles/UserPage.css';

const UserPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const access_token = localStorage.getItem('token');
    if (!access_token) {
      navigate('/login');
    } else {
      try {
        jwtDecode(access_token);
      } catch (error) {
        console.error('Invalid token:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        navigate('/login');
      }
    }
  }, [navigate]);

  const cards = [
    { title: 'My Products', path: '/productlist' },
    { title: 'Service Order History', path: '/servicehistory' },
    { title: 'Accessories List', path: '/accessorieslist' },
  ];

  

  return (
    <div>
      <Navbar />
      <div className="userpage4">
        <div className="card-container4">
          {cards.map((card, index) => (
            <Link key={index} to={card.path} className="card-link4">
              <div className="card4">
                <h3>{card.title}</h3>
              </div>
            </Link>
          ))}
        </div>
        <div className="other-products-heading4">
          Other Products
        </div>
        {/* Render ProductList with showAddToCartButton prop */}
      </div>
    </div>
  );
};

export default UserPage;
