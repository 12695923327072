import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Fixed import
import '../../styles/LoginPage.css';
import 'react-toastify/dist/ReactToastify.css';
import { login as authServiceLogin, refreshToken as authServiceRefreshToken } from '../../services/authService'; // Corrected import

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const checkTokenValidity = async () => {
    const accessToken = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');

    console.log('Checking token validity...');

    if (accessToken) {
      try {
        const decodedToken = jwtDecode(accessToken);
        const exp = decodedToken.exp; 
        console.log('Decoded token:', decodedToken);
        console.log('Token expiration time:', new Date(exp * 1000).toLocaleString()); 
        console.log('current time', Math.floor(Date.now() / 1000));
        console.log('exp', exp);

        if (Math.floor(Date.now() / 1000) <= exp) {
          const role = decodedToken.authorities.find(auth => auth.authority.startsWith('ROLE_'))?.authority;
          console.log('Token role:', role);

          switch (role?.toUpperCase()) {
            case 'ROLE_ADMIN':
              navigate('/admin');
              break;
            case 'ROLE_CUSTOMER':
              navigate('/user');
              break;
            case 'ROLE_MANAGER':
              navigate('/employee');
              break;
            case 'ROLE_TECHNICIAN':
              navigate('/technician');
              break;
            default:
              navigate('/home');
              break;
          }
        } else if (refreshToken) {
          console.log('Access token expired. Attempting to refresh...');
          const newTokens = await authServiceRefreshToken(refreshToken);
          if (newTokens && newTokens.access_token) {
            localStorage.setItem('token', newTokens.access_token);
            localStorage.setItem('refreshToken', newTokens.refresh_token);

            // Decode new token to get role
            const newDecodedToken = jwtDecode(newTokens.access_token);
            console.log('New decoded token:', newDecodedToken);
            const newRole = newDecodedToken.authorities.find(auth => auth.authority.startsWith('ROLE_'))?.authority;
            console.log('New token role:', newRole);

            switch (newRole?.toUpperCase()) {
              case 'ROLE_ADMIN':
                navigate('/admin');
                break;
              case 'ROLE_USER':
                navigate('/customer');
                break;
              case 'ROLE_MANAGER':
                navigate('/employee');
                break;
              case 'ROLE_TECHNICIAN':
                navigate('/technician');
                break;
              default:
                navigate('/home');
                break;
            }
          } else {
            console.log('Refresh token is invalid or expired. Redirecting to login.');
            navigate('/login');
          }
        } else {
          console.log('No refresh token available. Redirecting to login.');
          navigate('/login');
        }
      } catch (error) {
        console.error('Error handling tokens:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        navigate('/login');
      }
    } else {
      console.log('No access token available. Redirecting to login.');
      navigate('/login');
    }
  };

  useEffect(() => {
    checkTokenValidity();
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting login form with email:', email);

    try {
      const user = await authServiceLogin(email, password);
      console.log('Login response:', user);

      if (user && user.access_token) {
        localStorage.setItem('token', user.access_token);
        localStorage.setItem('refreshToken', user.refresh_token); // Store refresh token

        // Decode token to get role
        const decodedToken = jwtDecode(user.access_token);
        console.log('Decoded token after login:', decodedToken);
        const role = decodedToken.authorities.find(auth => auth.authority.startsWith('ROLE_'))?.authority;
        console.log('Role from token:', role);

        // Redirect based on the user's role
        switch (role?.toUpperCase()) {
          case 'ROLE_ADMIN':
            navigate('/admin');
            break;
          case 'ROLE_CUSTOMER':
            navigate('/user');
            break;
          case 'ROLE_MANAGER':
            navigate('/employee');
            break;
          case 'ROLE_TECHNICIAN':
            navigate('/technician');
            break;
          default:
            navigate('/home');
            break;
        }
      }
    } catch (error) {
      setError('Invalid credentials. Please try again.');
      console.error('Login error:', error);
    }
  };

  return (
    <div className="login-page">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {/* <div className="form-group">
          <label>Platform</label>
          <input
            type="text"
            value={platform}
            onChange={(e) => setPlatform(e.target.value)}
            required
          />
        </div> */}
        {error && <p className="error">{error}</p>}
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default LoginPage;
