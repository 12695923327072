import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/TechnicianList.css'; // Adjust path to CSS file as needed
import Navbar from '../Navbar/Navbar';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TechnicianList = () => {
  const [technicians, setTechnicians] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [technicianToDelete, setTechnicianToDelete] = useState(null);
  const [editTechnicianData, setEditTechnicianData] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    password: '',
    role: 'TECHNICIAN'
  });
  const [newTechnicianData, setNewTechnicianData] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    password: '',
    role: 'TECHNICIAN'
  });

  useEffect(() => {
    const fetchTechnicians = async () => {
      try {
        const access_token = localStorage.getItem('token');
        if (!access_token) {
          throw new Error('No token found');
        }

        const response = await axios.get(
          `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}?user_type=TECHNICIAN`,
          { headers: { Authorization: `Bearer ${access_token}` } }
        );

        setTechnicians(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching technicians:', error);
        setError('Error fetching technicians. Please try again later.');
        setLoading(false);
      }
    };

    fetchTechnicians();
  }, []);

  const handleEditClick = (technician) => {
    setEditTechnicianData(technician);
    setShowEditPopup(true);
  };

  const handleEditTechnician = async (event) => {
    event.preventDefault();

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      const response = await axios.put(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}/${editTechnicianData.id}`,
        editTechnicianData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        const updatedTechnicians = technicians.map(tech =>
          tech.id === editTechnicianData.id ? editTechnicianData : tech
        );
        setTechnicians(updatedTechnicians);
        setShowEditPopup(false);
        toast.success('Employee edited successfully!');
      } else {
        console.error('Failed to edit technician:', response);
      }
    } catch (error) {
      console.error('Error editing technician:', error);
      setError('Error editing technician. Please try again later.');
    }
  };

  const handleDeleteClick = (technician) => {
    setTechnicianToDelete(technician);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!technicianToDelete) return;
    const { id } = technicianToDelete;
    setShowModal(false);

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      const response = await axios.delete(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}/${id}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );

      if (response.status === 200) {
        toast.success('Delete successfully!');
        const updatedTechnicians = technicians.filter(tech => tech.id !== id);
        setTechnicians(updatedTechnicians);
        setTechnicianToDelete(null);
      } else {
        console.error('Failed to delete technician:', response);
        throw new Error('Failed to delete technician');
      }
    } catch (error) {
      console.error('Error deleting technician:', error);
      setError('Error deleting technician. Please try again later.');
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setTechnicianToDelete(null);
  };

  const handleAddTechnician = async (event) => {
    event.preventDefault();
  
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }
  
      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}`,
        newTechnicianData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.status === 200) {
        const updatedResponse = await axios.get(
          `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}?user_type=TECHNICIAN`,
          { headers: { Authorization: `Bearer ${access_token}` } }
        );
  
        setTechnicians(updatedResponse.data);
        setShowAddPopup(false);
        toast.success('Technician added successfully!');
      } else {
        toast.error('Failed to add technician. Please try again.');
      }
    } catch (error) {
      console.error('Error adding technician:', error);
      setError('Error adding technician. Please try again later.');
      toast.error('Error adding technician. Please try again.');
    }
  };
  
  const handleAddTechnicianClick = () => {
    setNewTechnicianData({
      name: '',
      email: '',
      mobileNumber: '',
      password: '',
      role: 'TECHNICIAN'
    });
    setShowAddPopup(true);
  };

  const handleClosePopup = () => {
    setShowAddPopup(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTechnicians = technicians.filter(
    (technician) =>
      technician && technician.name && technician.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <ToastContainer />
      <Navbar />
      <div className="technician-list-container">
        <div className="technician-list-actions">
          <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input10"
          />
          <h1>Technician List</h1>
          <button className="add-technician-button" onClick={handleAddTechnicianClick}>Add Technician</button>
        </div>

        <div className="table-responsive">
        <table className="technician-list-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile Number</th>
              <th>User Roles</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredTechnicians.map((technician) => (
              <tr key={technician.id}>
                <td>{technician.name}</td>
                <td>{technician.email}</td>
                <td>{technician.mobileNumber}</td>
                <td>{technician.role}</td>
                <td>
                  <div className="action-buttons-container">
                    <button className="edit-button9" onClick={() => handleEditClick(technician)}>Edit</button>
                    <button className="delete-button9" onClick={() => handleDeleteClick(technician)}>Delete</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>

        {showAddPopup && (
          <div className="technician-popup">
            <div className="technician-popup-inner">
              <h2>Add Technician</h2>
              <form onSubmit={handleAddTechnician}>

              <div className="form-group-tech">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  value={newTechnicianData.name || ''}
                  onChange={(e) =>
                    setNewTechnicianData({ ...newTechnicianData, name: e.target.value })
                  }
                  required
                />
                </div>

                <div className="form-group-tech">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  value={newTechnicianData.email || ''}
                  onChange={(e) =>
                    setNewTechnicianData({ ...newTechnicianData, email: e.target.value })
                  }
                  required
                />
                </div>

                <div className="form-group-tech">
                <label htmlFor="mobile">Mobile Number:</label>
                <input
                  type="text"
                  id="mobile"
                  value={newTechnicianData.mobileNumber || ''}
                  onChange={(e) =>
                    setNewTechnicianData({ ...newTechnicianData, mobileNumber: e.target.value })
                  }
                  required
                  pattern="\d{10}"
                  maxLength="10"
                />
                </div>

                <div className="form-group-tech">
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  value={newTechnicianData.password || ''}
                  onChange={(e) =>
                    setNewTechnicianData({ ...newTechnicianData, password: e.target.value })
                  }
                  required
                   pattern=".{8,}"
                  title="Password must be at least 8 characters long."
                />
                </div>
                
                <div className="button-container32">
                  <button type="button" className="cancel-button32" onClick={handleClosePopup}>Cancel</button>
                  <button type="submits" className="save-button32">Add Technician</button>
                  <button className="close-button32" onClick={handleClosePopup}>Close</button>
                </div>
                
              </form>
            </div>
          </div>
        )}

        {showEditPopup && (
          <div className="technician-popup">
            <div className="technician-popup-inner">
              <h2>Edit Technician</h2>
              <form onSubmit={handleEditTechnician}>

              <div className="form-group-tech">
                <label htmlFor="edit-name">Name:</label>
                <input
                  type="text"
                  id="edit-name"
                  value={editTechnicianData.name || ''}
                  onChange={(e) =>
                    setEditTechnicianData({ ...editTechnicianData, name: e.target.value })
                  }
                  required
                />
                </div>

                <div className="form-group-tech">
                <label htmlFor="edit-email">Email:</label>
                <input
                  type="email"
                  id="edit-email"
                  value={editTechnicianData.email || ''}
                  onChange={(e) =>
                    setEditTechnicianData({ ...editTechnicianData, email: e.target.value })
                  }
                  required
                />
                </div>

                <div className="form-group-tech">
                <label htmlFor="edit-mobile">Mobile Number:</label>
                <input
                  type="text"
                  id="edit-mobile"
                  value={editTechnicianData.mobileNumber || ''}
                  onChange={(e) =>
                    setEditTechnicianData({ ...editTechnicianData, mobileNumber: e.target.value })
                  }
                  required
                  pattern="\d{10}"
                  maxLength="10"
                  title="Please enter a valid 10-digit mobile number."
                />
                </div>
               
                <div className="button-container32">
                  <button type="button" className="cancel-button32" onClick={() => setShowEditPopup(false)}>Cancel</button>
                  <button type="submits" className='save-button32'>Save Changes</button>
                  <button className="close-button32" onClick={() => setShowEditPopup(false)}>Close</button>
                </div>
                
              </form>
            </div>
          </div>
        )}

         {showModal && technicianToDelete && (
        <div className="confirmation-modal">
          <div className="confirmation-modal-inner">
            <h2>Confirm Delete</h2>
            <p>Are you sure you want to delete technician {technicianToDelete.name}?</p>
            <button onClick={handleConfirmDelete} className="confirm-buttont">Yes</button>
            <button onClick={handleCancelDelete} className="cancel-buttont">No</button>
          </div>
        </div>
         )}
      </div>
    </div>
  );
};

export default TechnicianList;
