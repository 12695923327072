import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import { useParams, useNavigate } from 'react-router-dom';
import '../../styles/ServiceRequestDetails.css';
import Navbar from '../Navbar/Navbar';
import 'react-resizable/css/styles.css';
import UserSearchDropdown from '../UserSearchDropdown/UserSearchDropdown';
import ProductSearchDropdown from '../ProductSearchDropdown/ProductSearchDropdown';
import TechnicianSearchDropdown from '../TechnicianSearchDropdown/TechnicianSearchDropdown';
import { Dialog, DialogActions, DialogContent, Button, FormControl, InputLabel, Select, MenuItem, Box, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import AccessorySearchDropdown from '../AccessorySearchDropdown/AccessorySearchDropdown';

const ServiceRequestDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [serviceRequest, setServiceRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [technicianId, setTechnicianId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTechnicianConfirmDialog, setShowTechnicianConfirmDialog] = useState(false);
  const [isTechnicianAssigned, setIsTechnicianAssigned] = useState(false);
  // const [showEditPopup, setShowEditPopup] = useState(false);

  const [formData, setFormData] = useState({
    size: 10,
    hasNextPage: true,
    totalPages: 3,
    hasPreviousPage: false,
    page: 1,
    content: [{
      customerId: null,
      productId: null,
      technicianId: null,
      issueDescription: {
        issues: {
          issue1: false,
          issue2: false,
          issue3: false,
          issue4: false
        },
        description: ''
      },
      status: 'PENDING'
    }]
  });
  const [accessoriesMap, setaccessoriesMap] = useState({});

  const [showAccessoryInput, setShowAccessoryInput] = useState(false);
  const [accessories, setAccessories] = useState([]);
  const [accessorySelectionVisible, setAccessorySelectionVisible] = useState(false);
  const [selectedAccessory, setSelectedAccessory] = useState({ id: '', quantity: '' });

  const [showRadioButtons, setShowRadioButtons] = useState(false);
  const [addAccessories, setAddAccessories] = useState('no'); // 'yes' or 'no'


  useEffect(() => {
    const fetchServiceRequests = async () => {
      try {
        const access_token = localStorage.getItem('token');
        if (!access_token) throw new Error('No token found');

        const response = await axios.get(
          `${ApiEndpoints.BASE_URL}${ApiEndpoints.SERVICEREQUEST_URL}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              'Content-Type': 'application/json'
            }
          }
        );

        const serviceRequest = response.data.content ? response.data.content[0] : response.data;

        if (!serviceRequest) {
          throw new Error('Service request data is missing');
        }

        setServiceRequest(serviceRequest);
        setFormData({
          customerId: serviceRequest.customer ? serviceRequest.customer.id : 'N/A',
          productId: serviceRequest.product ? serviceRequest.product.id : 'N/A',
          technicianId: serviceRequest.technician ? serviceRequest.technician.id : 'N/A',
          issueDescription: serviceRequest.issueDescription || { issues: {}, description: '' },
          status: serviceRequest.status || 'N/A'
        });

        setLoading(false);
      } catch (error) {
        console.error('Error fetching service request details:', error);
        setError('Error fetching service request details. Please try again later.');
        setLoading(false);
      }
    };

    fetchServiceRequests();
  }, [id]);

  const handleUserSelection = (user) => {
    setFormData(prev => ({
      ...prev,
      customerId: user.id
    }));
  };

  const handleProductSelection = (product) => {
    setFormData(prev => ({
      ...prev,
      productId: product.id
    }));
  };


  const handleTechnicianSelection = (technician) => {
    setTechnicianId(technician.id);
    setIsTechnicianAssigned(true);
    setShowTechnicianConfirmDialog(true);
    // console.log(technician.id);
  };

  const handleTechnicianConfirm = (confirm) => {
    setShowTechnicianConfirmDialog(false);
    if (confirm) {
      setFormData(prev => ({
        ...prev,
        technicianId: technicianId,
        status: 'ASSIGNED',
      }));
      setShowTechnicianConfirmDialog(false);
      setAccessorySelectionVisible(true);
      setShowAccessoryInput(true); // This should make accessory input visible
      setAddAccessories('yes'); // Update state to show accessory search bar
      setShowRadioButtons(false); // Hide radio buttons if accessory input is shown
    } else {
      setIsTechnicianAssigned(false);
      setAccessorySelectionVisible(false);
      setShowRadioButtons(true);
      setAddAccessories('no');
    }
  };


  const handleRadioChange = (event) => {
    const value = event.target.value;
    setAddAccessories(value);
    if (value === 'yes') {
      setAccessorySelectionVisible(true);
    } else {
      setAccessorySelectionVisible(false);
    }
  };

  const handleEdit = async () => {
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) throw new Error('No token found');

      if (isTechnicianAssigned) {
        AssignTechnician(id);
      }
      await axios.put(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.SERVICEREQUEST_URL}/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      navigate('/servicerequest');
    } catch (error) {
      console.error('Error updating service request:', error);
      setError('Error updating service request. Please try again later.');
    }
  };

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setFormData(prev => ({
      ...prev,
      status: newStatus
    }));
    // if (newStatus === 'ASSIGNED') {
    //   AssignTechnician();
    // }
  };
  const AssignTechnician = async () => {
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) throw new Error('No token found');
      await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.SERVICEREQUEST_URL}/${id}/assign?technicianId=${technicianId}`,
        accessoriesMap,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      navigate('/servicerequest');
    } catch (error) {
      console.error('Error assigning technician:', error);
      setError('Error assigning technician. Please try again later.');
    }
  };


  const handleDelete = async () => {
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) throw new Error('No token found');

      await axios.delete(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.SERVICEREQUEST_URL}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      navigate('/servicerequest');
    } catch (error) {
      console.error('Error deleting service request:', error);
      setError('Error deleting service request. Please try again later.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const issueLabels = {
    issue1: 'Repair needed',
    issue2: 'Sound coming from the device',
    issue3: 'Screen flickering',
    issue4: 'Battery not charging',
  };

  const handleRemoveAccessory = (idToRemove) => {
    // Remove the accessory from the array
    setAccessories((prev) => prev.filter((accessory) => accessory.id !== idToRemove));

    // Remove the accessory from the map
    setaccessoriesMap((prev) => {
      const newMap = { ...prev };
      delete newMap[idToRemove];
      return newMap;
    });
  };
  const handleAddAccessory = () => {
    if (selectedAccessory.id && selectedAccessory.quantity) {
      // Add the accessory to the array
      setAccessories((prev) => [...prev, selectedAccessory]);

      // Add or update the accessory in the map
      setaccessoriesMap((prev) => ({
        ...prev,
        [selectedAccessory.id]: selectedAccessory.quantity
      }));

      // Reset selection
      // setAccessorySelectionVisible(false);
      setSelectedAccessory({ id: '', name: '', quantity: '' });
    } else {
      alert("Please select an accessory and quantity.");
    }
  };


  return (
    <div>
      <Navbar />
      <div className="service-request-details11">
        <h1>Service Request Details</h1>
        {serviceRequest && (
          <table className="service-request-table11">
            <tbody>
              <tr>
                <th>Date:</th>
                <td>{serviceRequest.createdAt ? new Date(serviceRequest.createdAt).toLocaleString() : 'N/A'}</td>
              </tr>
              <tr>
                <th>Customer Name:</th>
                <td>{serviceRequest.customer?.name || 'N/A'}</td>
              </tr>
              <tr>
                <th>Product Name:</th>
                <td>{serviceRequest.product?.name || 'N/A'}</td>
              </tr>
              <tr>
                <th>Status:</th>
                <td>{serviceRequest.status || 'N/A'}</td>
              </tr>
              <tr>
                <th>Technician:</th>
                <td>{serviceRequest.technician?.name || 'N/A'}</td>
              </tr>
              <tr>
                <th>Selected Accessories:</th>
                <td>
                  {serviceRequest.accessories && serviceRequest.accessories.length > 0 ? (
                    <ul>
                      {serviceRequest.accessories.map((accessory) => (
                        <li key={accessory.id}>
                          {accessory.name} - Quantity: {accessory.quantity}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No accessories selected</p>
                  )}
                </td>
              </tr>
              <tr>
                <th>Issues:</th>
                <td>
                  {serviceRequest.issueDescription?.issues && Object.keys(serviceRequest.issueDescription.issues).length > 0 ? (
                    <ul>
                      {Object.entries(serviceRequest.issueDescription.issues)
                        .filter(([key, value]) => value) // Only include issues that are selected
                        .map(([key]) => (
                          <li key={key}>
                            {issueLabels[key] || 'Unknown issue'}
                          </li>
                        ))}
                    </ul>
                  ) : (
                    <p>No issues selected</p>
                  )}
                </td>
              </tr>
              <tr>
                <th>Issue Description</th>
                <td>{serviceRequest.issueDescription?.description || 'N/A'}</td>
              </tr>
            </tbody>
          </table>
        )}


        <div className="service-request-actions11">
          <button className="edit-button11" onClick={() => setShowEditModal(true)}>Edit</button>
          <button className="delete-button11" onClick={() => setShowDeleteModal(true)}>Delete</button>
        </div>

        {showEditModal && (
          <div className="modal11 edit-modal11">
            <h2>Edit Service Request</h2>
            <div className="modal-content11">

              {/* User Search Dropdown */}
              <div className="form-group">
                <label>Customer:</label>
                <UserSearchDropdown
                  onUserSelect={handleUserSelection}
                  userName={serviceRequest.customer?.name || 'N/A'}
                />
              </div>

              {/* Product Search Dropdown */}
              <div className="form-group">
                <label>Product:</label>
                <ProductSearchDropdown
                  onProductSelect={handleProductSelection}
                  productName={serviceRequest.product?.name || 'N/A'}
                />
              </div>

              {/* Technician Search Dropdown */}
              <div className="form-group">
                <label>Technician:</label>
                <TechnicianSearchDropdown
                  onTechnicianSelect={handleTechnicianSelection}
                  technicianName={serviceRequest.technician?.name || 'N/A'}
                />
              </div>

              {/* Radio Buttons for Accessories */}
              {showRadioButtons && (
                <div className="form-group">
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={addAccessories}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </div>
              )}

              {/* Accessory Selection */}
              {accessorySelectionVisible && (
                <div className="form-group accessory-selection">
                  <h4>Accessory Selection</h4>
                  <Box display="flex" alignItems="center" gap={2}>
                    <AccessorySearchDropdown
                      onAccessorySelect={(accessory) =>
                        setSelectedAccessory((prev) => ({
                          ...prev,
                          id: accessory.id,
                          name: accessory.name,
                        }))
                      }
                    />
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Select Quantity</InputLabel>
                      <Select
                        value={selectedAccessory.quantity}
                        onChange={(e) =>
                          setSelectedAccessory((prev) => ({
                            ...prev,
                            quantity: e.target.value,
                          }))
                        }
                      >
                        <MenuItem value="">Select Quantity</MenuItem>
                        {[...Array(10).keys()].map((num) => (
                          <MenuItem key={num + 1} value={num + 1}>
                            {num + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddAccessory}
                    >
                      Add
                    </Button>
                  </Box>
                  <ul>
                    {accessories.map((accessory) => (
                      <li key={accessory.id}>
                        Accessory Name: {accessory.name}, Quantity: {accessory.quantity}
                        <button
                          className="remove-accessory-button"
                          onClick={() => handleRemoveAccessory(accessory.id)}
                        >
                          Remove
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Issue Description */}
              <div className="issue-description36">
              <div className="form-group-service">
              <label htmlFor="issues">Issues:</label>
              <div className="issue-list">
                  {Object.keys(formData.issueDescription.issues).map((key) => (
                    <div className="issue-item" key={key}>
                      <input
                        type="checkbox"
                        id={`edit-${key}`}
                        checked={formData.issueDescription.issues[key]}
                        onChange={(e) => setFormData(prev => ({
                          ...prev,
                          issueDescription: {
                            ...prev.issueDescription,
                            issues: { ...prev.issueDescription.issues, [key]: e.target.checked }
                          }
                        }))}
                      />
                      <label htmlFor={`edit-${key}`}>{issueLabels[key]}</label>
                    </div>
                  ))}
                </div>
                </div>
                </div>

                <div className="form-group-service36">
                  <label className="issue-description36">Issue Description:</label>
                  <textarea
                    value={formData.issueDescription.description}
                    onChange={(event) => {
                      const data = event.target.value;
                      setFormData(prev => ({
                        ...prev,
                        issueDescription: {
                          ...prev.issueDescription,
                          description: data
                        }
                      }));
                    }}
                    placeholder="Enter issue description here..."
                    className="textarea-container36"
                    rows="10"
                  />
                </div>
              

              {/* Status Selection */}
              <div className="form-group">
                <label>Status:</label>
                <select value={formData.status} onChange={handleStatusChange}>
                  <option value="PENDING">PENDING</option>
                  <option value="ASSIGNED">ASSIGNED</option>
                  <option value="COMPLETED">COMPLETED</option>
                </select>
              </div>

              {/* Button Container */}
              <div className="button-container11">
              <button type="button" className="cancel-button11" onClick={() => setShowEditModal(false)}>Cancel</button>
                <button type="submit" className="save-button11" onClick={handleEdit}>Save Changes</button>
                <button type="button" className="close-button11" onClick={() => setShowEditModal(false)}>Close</button>
              </div>
            </div>
          </div>
        )}


        {showDeleteModal && (
          <div className="modal11 delete-modal11">
            <h2>Confirm Deletion</h2>
            <p>Are you sure you want to delete this service request  {id}</p>
            <button className="confirm-button11" onClick={handleDelete}>Yes</button>
            <button className="cancel-button11" onClick={() => setShowDeleteModal(false)}>No</button>
          </div>
        )}

        <Dialog
          open={showTechnicianConfirmDialog}
          onClose={() => setShowTechnicianConfirmDialog(false)}
        >
          <DialogContent>
            <p>Do you want to add accessories ?</p>
          </DialogContent>
          <DialogActions>
            <Button value={showAccessoryInput} onClick={() => handleTechnicianConfirm(true)} color="primary">
              Yes
            </Button>
            <Button onClick={() => handleTechnicianConfirm(false)} color="secondary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default ServiceRequestDetails;
