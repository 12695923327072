import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import { Autocomplete, TextField, CircularProgress, FormControl, FormHelperText } from '@mui/material';

const AccessorySearchDropdown = ({ onAccessorySelect }) => {
  const [query, setQuery] = useState('');
  const [accessories, setAccessories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedAccessory, setSelectedAccessory] = useState(null);

  useEffect(() => {
    const fetchAccessories = async () => {
      if (query.length > 2) {
        setLoading(true);
        try {
          const access_token = localStorage.getItem('token');
          if (!access_token) throw new Error('No token found');
          const response = await axios.get(`${ApiEndpoints.BASE_URL}${ApiEndpoints.ACCESSORIESLIST_URL}/search?name=${query}`, {
            headers: {
              Authorization: `Bearer ${access_token}`,
              'Content-Type': 'application/json'
            }
          });
          setAccessories(response.data);
          setError('');
        } catch (error) {
          console.error('Error fetching accessories:', error);
          setError('Failed to load accessories');
        } finally {
          setLoading(false);
        }
      } else {
        setAccessories([]);
      }
    };

    fetchAccessories();
  }, [query]);

  return (
    <FormControl fullWidth error={Boolean(error)}>
      <Autocomplete
        freeSolo
        options={accessories}
        getOptionLabel={(option) => option.name || ''}
        value={selectedAccessory}
        onChange={(event, newValue) => {
          setSelectedAccessory(newValue);
          if (newValue) {
            onAccessorySelect(newValue);
          }
        }}
        onInputChange={(event, newInputValue) => setQuery(newInputValue)}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params) => (
          <TextField
            {...params}
            // label="Search accessories"
            placeholder='Search Accessories'
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                // Remove border from TextField
                '& fieldset': {
                  border: 'none', // Remove border
                },
                // '&:hover fieldset': {
                //   border: 'none', // Remove border on hover
                // },
                // '&.Mui-focused fieldset': {
                //   border: 'none', // Remove border when focused
                // },
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default AccessorySearchDropdown;
