import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../styles/AccessoriesList.css';
import Navbar from '../Navbar/Navbar';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';

const AccessoriesList = () => {
  const [accessories, setAccessories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [category, setCategory] = useState('ALL'); // Default category

  useEffect(() => {
    const fetchAccessories = async () => {
      try {
        const access_token = localStorage.getItem('token');
        if (!access_token) {
          throw new Error('No token found');
        }

        let url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.ACCESSORIESLIST_URL}/list`;
        if (category !== 'ALL') {
          url += `?category=${category}`;
        }

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });

        setAccessories(response.data);
      } catch (error) {
        console.error('Error fetching accessories:', error.message);
        setError('Error fetching accessories. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchAccessories();
  }, [category]); // Re-fetch when the category changes

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (

    <div>
      <Navbar/>
    <div className="accessories-list">
      <h1>Accessories List</h1>
      <div className="filter-container">
        <label htmlFor="category">Filter by category: </label>
        <select id="category" value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="ALL">All</option>
          <option value="Mechanical">Mechanical</option>
          <option value="Electrical">Electrical</option>
        </select>
      </div>
      <table className="accessory-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Thumbnail</th>
            <th>Accessory Name</th>
            <th>Category</th>
            <th>Stock</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {accessories.map((accessory, index) => (
            <tr key={accessory.id}>
              <td>{index + 1}</td>
              <td>
                <Link to={`/Accessory/${accessory.id}`} className="accessory-link">
                  <img src={ApiEndpoints.BASEURL_IMAGE + accessory.thumbnail} alt={accessory.name} className="accessory-thumbnail" />
                </Link>
              </td>
              <td>{accessory.name}</td>
              <td>{accessory.category}</td>
              <td>{accessory.stock}</td>
              <td>${accessory.price.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
};

export default AccessoriesList;
