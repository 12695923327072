// import React, { useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import {jwtDecode} from 'jwt-decode';
// import Navbar from '../Navbar/Navbar';
// import '../../styles/AdminPage.css';

// const AdminPage = () => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const access_token = localStorage.getItem('token');
//     if (!access_token) {
//       navigate('/login');
//     } else {
//       try {
//         jwtDecode(access_token);
//       } catch (error) {
//         console.error('Invalid token:', error);
//         localStorage.removeItem('token');
//         localStorage.removeItem('refreshToken');
//         navigate('/login');
//       }
//     }
//   }, [navigate]);

//   const cards = [
//     { title: 'Product List', path: '/productlistadmin' },
//     { title: 'Service Request', path: '/servicerequest' },
//     { title: 'Accessories List', path: '/access' },
//     { title: 'Employee List', path: '/employeeList' },
//     { title: 'Technician List', path: '/technicianList' },
//     { title: 'Customer List', path: '/customer' },
//     { title: 'Sales Order History', path: '/saleshistory' },
//     { title: 'Inventory', path: '/inventory' },
//     { title: 'Point Of Sale', path: '/pointofsale' },

//   ];


//   return (
//     <div>  
//       <Navbar />
//       <div className="adminpage1">
//         <div className="card-container1">
//           {cards.map((card, index) => (
//             <Link key={index} to={card.path} className="card-link1">
//               <div className="card1">
//                 <h3>{card.title}</h3>
//               </div>
//             </Link>
//           ))}
//         </div>
        
//       </div>
//     </div>
//   );
// };

// export default AdminPage;

import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import { gsap } from 'gsap';
import Navbar from '../Navbar/Navbar';
import '../../styles/AdminPage.css';

const AdminPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const access_token = localStorage.getItem('token');
    if (!access_token) {
      navigate('/login');
    } else {
      try {
        jwtDecode(access_token);
      } catch (error) {
        console.error('Invalid token:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        navigate('/login');
      }
    }
  }, [navigate]);

  useEffect(() => {
    gsap.fromTo('.card1', 
      { opacity: 0, y: 20 }, 
      { opacity: 1, y: 0, duration: 1.7, stagger: 0.1, ease: 'power2.out' }
    );

    gsap.fromTo('.card1 h3', 
      { scale: 0.8, opacity: 0 }, 
      { scale: 1, opacity: 1, duration: 0.3, stagger: 0.1, ease: 'back.out(1.7)' }
    );
  }, []);

  const cards = [
    { title: 'Point Of Sale', path: '/pointofsale' },
    { title: 'Product List', path: '/productlistadmin' },
    { title: 'Service Request', path: '/servicerequest' },
    { title: 'Accessories List', path: '/access' },
    { title: 'Employee List', path: '/employeeList' },
    { title: 'Technician List', path: '/technicianList' },
    { title: 'Customer List', path: '/customer' },
    { title: 'Service Order History', path: '/serviceorderhistory' },
    { title: 'Inventory', path: '/inventory' },
    { title: 'Sales Order History', path: '/saleshistory' },
    ];

  return (
    <div>
      <Navbar />
      <div className="adminpage1">
        <div className="card-container1">
          {cards.map((card, index) => (
            <Link key={index} to={card.path} className="card-link1">
              <div className="card1">
                <h3>{card.title}</h3>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;

