import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Navbar.css';
import Profile from '../Profile/Profile';
import companylogo from '../../assets/images/companylogo.png'

const Navbar = () => {
  const [ isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <div>
      <nav className="navbar">
        <div className="navbar-left">
        <img src={companylogo} alt="Company Logo" className="navbar-logo" />
          <span className="navbar-company-name">Marvel International</span>
        </div>
        <div className="navbar-right">
          <Link to="/admin" className="navbar-link">Home</Link>
          <span onClick={toggleDrawer} className="navbar-link">Account</span> 
        </div>
      </nav>
      <Profile isOpen={isDrawerOpen} onClose={toggleDrawer}/>
    </div>
  );
};

export default Navbar;
