import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import { Autocomplete, TextField, CircularProgress, FormControl, FormHelperText } from '@mui/material';
import '../../styles/ProductSearchDropdown.css';

const ProductSearchDropdown = ({ onProductSelect, productName }) => {
  const [query, setQuery] = useState(productName || '');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (productName) {
      setQuery(productName);
    }
  }, [productName]);

  useEffect(() => {
    const fetchProducts = async () => {
      if (query.length > 2) {
        setLoading(true);
        try {
          const access_token = localStorage.getItem('token');
          if (!access_token) {
            throw new Error('No token found');
          }
          const response = await axios.get(`${ApiEndpoints.BASE_URL}${ApiEndpoints.CREATE_PRODUCT_URL}/search?name=${query}`, {
            headers: {
              Authorization: `Bearer ${access_token}`,
              'Content-Type': 'application/json'
            }
          });
          setProducts(response.data);
          setError(''); // Clear previous errors
        } catch (error) {
          console.error('Error fetching products:', error);
          setError('Failed to load products');
        } finally {
          setLoading(false);
        }
      } else {
        setProducts([]);
      }
    };

    fetchProducts();
  }, [query]);

  return (
    <FormControl fullWidth error={Boolean(error)}>
      <Autocomplete
        freeSolo
        options={products}
        getOptionLabel={(option) => option.name || ''}
        value={products.find(product => product.name === query) || null} // Set the value to the selected product
        onInputChange={(event, newInputValue) => setQuery(newInputValue)}
        onChange={(event, newValue) => {
          if (newValue) {
            onProductSelect(newValue);
            setQuery(newValue.name); // Set the input field to the selected product's name
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            // label="Search product name..."
            placeholder='Search Products'
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                // Remove border from TextField
                '& fieldset': {
                  border: 'none', // Remove border
                },
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default ProductSearchDropdown;
