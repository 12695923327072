import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import '../../styles/Access.css';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Access = () => {
  const [Accessory, setAccessory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const [category, setCategory] = useState('ALL');
  const [showModal, setShowModal] = useState(false);
  const [accessoryIdToDelete, setAccessoryIdToDelete] = useState(null);
  const [newAccessoryData, setNewAccessoryData] = useState({
    name: '',
    price: '',
    description: '',
    thumbnail: '',
    images: [],
    category: '',
    stock: ''
  });

  const [previewImaget, setPreviewImaget] = useState(null);
  const [previewImagesi, setPreviewImagesi] = useState([]);

  useEffect(() => {
    const fetchAccessories = async () => {
      try {
        const access_token = localStorage.getItem('token');
        if (!access_token) {
          throw new Error('No token found');
        }

        let url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.ACCESSORIESLIST_URL}/list`;
        if (category !== 'ALL') {
          url += `?category=${category}`;
        }

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });

        setAccessory(response.data);
      } catch (error) {
        console.error('Error fetching Accessory:', error.message);
        setError('Error fetching Accessory. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchAccessories();
  }, [category]);

  const handleEditClick = (accessory) => {
    setEditFormData(accessory);
    setPreviewImaget(ApiEndpoints.BASEURL_IMAGE + accessory.thumbnail);
    const imagesUrls = accessory.images.map(image => ApiEndpoints.BASEURL_IMAGE + image);
    setPreviewImagesi(imagesUrls);
    setShowEditPopup(true);
  };

  const handleEditProduct = async (event) => {
    event.preventDefault();

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      let imageUrlt = editFormData.thumbnail;
      if (editFormData.thumbnail instanceof File) {
        imageUrlt = await uploadImageAndGetURL(editFormData.thumbnail, access_token);
      }

      let imagesUrls = editFormData.images
        .filter(image => typeof image === 'string' && !image.startsWith('deleted'));

      if (editFormData.images.some(file => file instanceof File)) {
        const newImagesUrls = await Promise.all(
          editFormData.images
            .filter(file => file instanceof File)
            .map(file => uploadImageAndGetURL(file, access_token))
        );
        imagesUrls = [...imagesUrls, ...newImagesUrls];
      }

      const productData = {
        name: editFormData.name,
        price: editFormData.price,
        description: editFormData.description,
        category: editFormData.category,
        stock: editFormData.stock,
        thumbnail: imageUrlt,
        images: imagesUrls,
      };

      const response = await axios.put(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.ACCESSORIESLIST_URL}/${editFormData.id}`,
        productData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        const updatedProducts = Accessory.map(accessory =>
          accessory.id === editFormData.id ? { ...productData, id: accessory.id } : accessory
        );
        setAccessory(updatedProducts);
        setShowEditPopup(false);
        toast.success('Accessory edited successfully!');
      } else {
        console.error('Failed to edit accessory:', response);
      }
    } catch (error) {
      console.error('Error editing accessory:', error);
      setError('Error editing accessory. Please try again later.');
      console.log(error.message);
    }
  };

  const handleDeleteClick = (accessory) => {
    setAccessoryIdToDelete(accessory);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setShowModal(false);

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        console.error('No token found');
        throw new Error('No token found');
      }
      console.log('Attempting to delete accessory with ID:', accessoryIdToDelete.id);
      const response = await axios.delete(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.ACCESSORIESLIST_URL}/${accessoryIdToDelete.id}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      console.log('Delete request response:', response);
      if (response.status === 200) {

        console.log('Accessory deleted successfully:', accessoryIdToDelete.id);
        const updatedAccessories = Accessory.filter(item => item.id !== accessoryIdToDelete.id);
        setAccessory(updatedAccessories);
        toast.success('Accessory deleted successfully!');
      } else {
        console.error('Failed to delete accessory:', response);
        throw new Error('Failed to delete accessory');
      }
    } catch (error) {
      console.error('Error deleting accessory:', error);
      setError('Error deleting accessory. Please try again later.');
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
  };

  const uploadImageAndGetURL = async (imageFile, access_token) => {
    try {
      console.log('Uploading image:', imageFile);

      const formData = new FormData();
      formData.append('file', imageFile);

      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.PRODUCT_THUMBNAIL_UPLOAD_URL}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      console.log('Upload response:', response);

      if (response.status === 200) {
        const imageUrlt = response.data.file_path;
        console.log('Image uploaded successfully. Image URL:', imageUrlt);
        return imageUrlt;
      } else {
        console.error('Failed to upload image:', response);
        throw new Error('Failed to upload image');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      throw new Error('Error uploading image');
    }
  };

  const handleAddProduct = async (event) => {
    event.preventDefault();

    try {
      const accessToken = localStorage.getItem('token');
      if (!accessToken) throw new Error('No token found');

      let imageUrlt = null;
      let imagesUrls = [];

      if (newAccessoryData.images.length > 0) {
        imagesUrls = await Promise.all(
          newAccessoryData.images.map(file => uploadImageAndGetURL(file, accessToken))
        );
      }

      if (newAccessoryData.thumbnail instanceof File) {
        imageUrlt = await uploadImageAndGetURL(newAccessoryData.thumbnail, accessToken);
      }

      const productData = {
        name: newAccessoryData.name,
        price: newAccessoryData.price,
        description: newAccessoryData.description,
        category: newAccessoryData.category, // Fixed to use newAccessoryData.category
        stock: newAccessoryData.stock,
        thumbnail: imageUrlt,
        images: imagesUrls,
      };

      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.ACCESSORIESLIST_URL}`,
        productData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setAccessory([...Accessory, response.data]);
        setShowAddPopup(false);
        toast.success('Accessory added successfully!');
      } else {
        throw new Error('Failed to add accessory');
      }
    } catch (error) {
      console.error('Error adding accessory:', error.response ? error.response.data : error.message);
      setError('Error adding accessory. Please try again later.');
    }
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setNewAccessoryData({ ...newAccessoryData, thumbnail: file });
    const imageUrlt = URL.createObjectURL(file);
    setPreviewImaget(imageUrlt);
  };

  const handleThumbnailChangest = (e) => {
    const file = e.target.files[0];
    setEditFormData({ ...editFormData, thumbnail: file });
    const imageUrlt = URL.createObjectURL(file);
    setPreviewImaget(imageUrlt);
  };

  const deleteThumbnails = async (imageUrlt) => {
    try {
      const access_token = localStorage.getItem('token');
      const fileName = imageUrlt.replace(ApiEndpoints.BASEURL_IMAGE, '');
      if (!access_token) {
        throw new Error('No token found');
      }

      // Call to delete the image from server
      const response = await axios.delete(`${ApiEndpoints.BASE_URL}${ApiEndpoints.DELETE_IMAGE_URL}?fileName=${fileName}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'application/json'
        },
      });

      if (response.status === 200) {
        setPreviewImaget(null);
        setEditFormData({
          ...editFormData,
          thumbnail: 'deleted' // Mark the image as deleted
        });
        toast.success('Image deleted successfully');
      } else {
        throw new Error('Failed to delete image');
      }
    } catch (error) {
      console.error('Error deleting thumbnail:', error);
      toast.error('Error deleting thumbnail');
    }
  };

  const handleImagesChange = (e) => {
    const files = Array.from(e.target.files);
    setNewAccessoryData({ ...newAccessoryData, images: [...newAccessoryData.images, ...files] });

    // Preview images
    const imagesUrls = files.map(file => URL.createObjectURL(file));
    setPreviewImagesi([...previewImagesi, ...imagesUrls]);
  };

  const handleImagesChangesi = (e) => {
    const files = Array.from(e.target.files);

    // Update the state with new files
    setEditFormData(prevData => ({
      ...prevData,
      images: [...prevData.images, ...files] // Append new files
    }));

    // Generate previews for the new files
    const newImagesUrls = files.map(file => URL.createObjectURL(file));
    setPreviewImagesi(prevImages => [...prevImages, ...newImagesUrls]);
  };

  const deleteImages = async (index) => {
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      const imageToDelete = editFormData.images[index];
      const fileName = imageToDelete.replace(ApiEndpoints.BASEURL_IMAGE, '');

      await axios.delete(`${ApiEndpoints.BASE_URL}${ApiEndpoints.DELETE_IMAGE_URL}?fileName=${fileName}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'application/json',
        },
      });

      // Remove the image from preview and state
      const updatedPreviewImages = [...previewImagesi];
      updatedPreviewImages.splice(index, 1);
      setPreviewImagesi(updatedPreviewImages);

      const updatedImages = [...editFormData.images];
      updatedImages.splice(index, 1);
      setEditFormData({ ...editFormData, images: updatedImages });

    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const handleAddAccessClick = () => {
    // Reset newCustomerData before showing the popup
    setNewAccessoryData({
      name: '',
      category: '',
      stock: '',
      price: '',
      description: '',
      thumbnail: null,
      images: []
    });
    setPreviewImaget(null);
    setPreviewImagesi([]);
    setShowAddPopup(true);
  };
  // Handle close popup
  const handleClosePopup = () => {
    setShowAddPopup(false);
  };

  const filteredProducts = Accessory.filter(
    (accessory) =>
      accessory.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Navbar />
      <ToastContainer />
      <div className="accessory-list-admin-container1">
        <div className="accessory-list-admin-content1">
          {/* <h1>Accessories List</h1> */}
          <div className="accessory-list-actions1">
            <input
              type="text"
              placeholder="Search by name or ID..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <h1>Accessories List</h1>
            <button className="add-accessory-button1" onClick={handleAddAccessClick}>Add Accessory</button>
          </div>
          <div className="filter-container">
            <span
              className={`filter-option ${category === "ELECTRICAL" ? "active" : ""}`}
              onClick={() => setCategory("ELECTRICAL")}
            >
              Electrician
            </span>
            <span
              className={`filter-option ${category === "MECHANICAL" ? "active" : ""}`}
              onClick={() => setCategory("MECHANICAL")}
            >
              Mechanical
            </span>
            <span
              className={`filter-option ${category === "ALL" ? "active" : ""}`}
              onClick={() => setCategory("ALL")}
            >
              All
            </span>
          </div>

          <table className="accessory-list-table1">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Image</th>
                <th>Accessory Name</th>
                <th>Category</th>
                <th>Stock</th>
                <th>Price</th>
                <th>Actions</th>

              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((accessory, index) => (
                <tr key={accessory.id}>
                  <td>{index + 1}</td>
                  <td>
                    <Link to={`/access/${accessory.id}`} className="accessory-link1">
                      <img src={ApiEndpoints.BASEURL_IMAGE + accessory.thumbnail} alt={accessory.name} className="accessory-thumbnail1" />
                    </Link>
                  </td>
                  <td>{accessory.name}</td>
                  <td>{accessory.category}</td>
                  <td>{accessory.stock}</td>
                  <td>{accessory.price}</td>

                  <td>
                    <div className="action-buttons-container">
                      <button className="edit-button12" onClick={() => handleEditClick(accessory)}>Edit</button>
                      <button className="delete-button12" onClick={() => handleDeleteClick(accessory)}>Delete</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {showAddPopup && (
          <div className="popup1">
            <div className="popup-inner1">
              <h2>Add Accessory</h2>
              <form onSubmit={handleAddProduct} encType="multipart/form-data">
              <div className="form-group">
                <label htmlFor="name">Accessory Name:</label>
                <input
                  type="text"
                  id="name"
                  value={newAccessoryData.name || ''}
                  onChange={(e) =>
                    setNewAccessoryData({ ...newAccessoryData, name: e.target.value })
                  }
                  required
                />
                </div>

                <div className="form-group">
                <label htmlFor="category">Category:</label>
                <select
                  id="category"
                  name="category"
                  value={newAccessoryData.category || ''}
                  onChange={(e) =>
                    setNewAccessoryData({ ...newAccessoryData, category: e.target.value })
                  }
                >
                  <option value="">Select Category</option>
                  <option >MECHANICAL</option>
                  <option >ELECTRICAL</option>
                </select>
                </div>

                <div className="form-group">
                <label htmlFor="stock">Stock:</label>
                <input
                  type="text"
                  id="stock"
                  value={newAccessoryData.stock || ''}
                  onChange={(e) =>
                    setNewAccessoryData({ ...newAccessoryData, stock: e.target.value })
                  }
                  required
                />
                </div>

                <div className="form-group">
                <label htmlFor="price">Price:</label>
                <input
                  type="text"
                  id="price"
                  value={newAccessoryData.price || ''}
                  onChange={(e) =>
                    setNewAccessoryData({ ...newAccessoryData, price: e.target.value })
                  }
                  required
                />
                </div>

                <div className="form-group">
                <label className="description1">Description:</label>
                <textarea
                  value={newAccessoryData.description || ''}
                  onChange={(event) =>
                    setNewAccessoryData({ ...newAccessoryData, description: event.target.value })
                  }
                  placeholder="Enter description here..."
                  className="textarea-container"
                  rows="10"
                />
                </div>

                <div className="form-group">
                <label htmlFor="thumbnail">Thumbnail:</label>
                <input
                  type="file"
                  id="thumbnail"
                  onChange={handleThumbnailChange}
                  accept="image/*"
                  required
                />
                {previewImaget && (
                  <div className="image-preview-container1">
                    <img src={previewImaget} alt="Thumbnail Preview" className="image-preview1" />
                    <button
                      className="delete-image-button1"
                      onClick={() => {
                        setPreviewImaget(null);
                        setNewAccessoryData({ ...newAccessoryData, thumbnail: null });
                      }}
                    >
                      Delete
                    </button>
                  </div>
                )}
                </div>

                <div className="form-group">
                <label htmlFor="images">Images:</label>
                <input
                  type="file"
                  id="images"
                  onChange={handleImagesChange}
                  accept="image/*"
                  multiple
                />
                {previewImagesi.length > 0 && (
                  <div className="images-preview1">
                    {previewImagesi.map((imageUrlt, index) => (
                      <div key={index} className="image-preview-container1">
                        <img src={imageUrlt} alt={`Preview ${index}`} className="image-preview1" />
                        <button
                          className="delete-image-button1"
                          onClick={() => {
                            const updatedPreviewImages = [...previewImagesi];
                            updatedPreviewImages.splice(index, 1);
                            setPreviewImagesi(updatedPreviewImages);

                            const updatedImages = [...newAccessoryData.images];
                            updatedImages.splice(index, 1);
                            setNewAccessoryData({ ...newAccessoryData, images: updatedImages });
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                  </div>
                )}
                </div>

                <div className="button-container22">
                  <button type="button" className="cancel-button22" onClick={handleClosePopup}>Cancel</button>
                  <button type="submits" className="save-button22">Add Accessory</button>
                  <button className="close-button22" onClick={handleClosePopup}>Close</button>
                </div>
              </form>
            </div>
          </div>
        )}

        {showEditPopup && (
          <div className="popup1">
            <div className="popup-inner1">
              <h2>Edit Accessory</h2>
              <form onSubmit={handleEditProduct} encType="multipart/form-data">
              <div className="form-group">
                <label htmlFor="edit-name">Product Name:</label>
                <input
                  type="text"
                  id="edit-name"
                  value={editFormData.name}
                  onChange={(e) =>
                    setEditFormData({ ...editFormData, name: e.target.value })
                  }
                  required
                />
                </div>

                <div className="form-group">
                <label htmlFor="category">Category:</label>
                <select
                  id="category"
                  name="category"
                  value={editFormData.category}
                  onChange={(e) =>
                    setEditFormData({ ...editFormData, category: e.target.value })
                  }
                  required
                >
                  <option value="">Select Category</option>
                  <option >MECHANICAL</option>
                  <option >ELECTRICAL</option>
                </select>
                </div>

                <div className="form-group">
                <label htmlFor="stock">Stock:</label>
                <input
                  type="text"
                  id="stock"
                  value={editFormData.stock || ''}
                  onChange={(e) =>
                    setEditFormData({ ...editFormData, stock: e.target.value })
                  }
                  required
                />
                </div>

                <div className="form-group">
                <label htmlFor="edit-price">Price:</label>
                <input
                  type="text"
                  id="edit-price"
                  value={editFormData.price}
                  onChange={(e) =>
                    setEditFormData({ ...editFormData, price: e.target.value })
                  }
                  required
                />
                </div>

                <div className="form-group">
                <label className="description1">Description:</label>
                <textarea
                  value={editFormData.description || ''}
                  onChange={(event) =>
                    setEditFormData({ ...editFormData, description: event.target.value })
                  }
                  placeholder="Enter description here..."
                  className="textarea-container"
                  rows="10"
                />
                </div>

                <div className="form-group">
                <label htmlFor="edit-thumbnail">Thumbnail:</label>
                <input
                  type="file"
                  id="edit-thumbnail"
                  onChange={handleThumbnailChangest}
                  accept="image/*"
                />
                {previewImaget && (
                  <div className="image-preview-container1">
                    <img src={previewImaget} alt="Thumbnail Preview" className="image-preview1" />
                    <button
                      className="delete-image-button1"
                      type="button"
                      onClick={() => deleteThumbnails(previewImaget)}
                    >
                      Delete
                    </button>
                  </div>
                )}
                </div>

                <div className="form-group">
                <label htmlFor="edit-images">Images:</label>
                <input
                  type="file"
                  id="edit-images"
                  onChange={handleImagesChangesi}
                  accept="image/*"
                  multiple
                />
                {previewImagesi.length > 0 && (
                  <div className="images-preview1">
                    {previewImagesi.map((imagesUrls, index) => (
                      <div key={index} className="image-preview-container1">
                        <img src={imagesUrls} alt={`Preview ${index}`} className="image-preview1" />
                        <button
                          className="delete-image-button1"
                          type="button"
                          onClick={() => deleteImages(index)}
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                  </div>
                )}
                </div>
                
                <div className="button-container22">
                <button type="button" className="cancel-button22" onClick={() => setShowEditPopup(false)}>Cancel</button>
                  <button type="submits" className="save-button22">Save Changes</button>
                  <button className="close-button22" onClick={() => setShowEditPopup(false)}>Close</button>
                </div>
              </form>
            </div>
          </div>
        )}

        {showModal && (
          <div className="modal-overlayA">
            <div className="modal-contentA">
              <p>Are you sure you want to delete the {accessoryIdToDelete.name}?</p>
              <div className="button-containerA">
                <button className='ConfirmA' onClick={handleConfirmDelete}>Yes</button>
                <button className='CancelA' onClick={handleCancelDelete}>No</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Access;
