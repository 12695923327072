import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/Inventory.css'; // Adjust path to CSS file as needed
import Navbar from '../Navbar/Navbar';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InventoryModal from '../InventoryModal/InventoryModal';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AccessorySearchDropdown from '../AccessorySearchDropdown/AccessorySearchDropdown';
import TechnicianSearchDropdown from '../TechnicianSearchDropdown/TechnicianSearchDropdown';

const Inventory = () => {
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [newInventoryItem, setNewInventoryItem] = useState({
    id: '',
    technicianId: '',
    accessoryId: '',
    quantity: '',
    technicianName: '',
    accessoryName: ''

  });
  const [selectedItem, setSelectedItem] = useState(null); // For viewing item details
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [technicianIdToDelete, setTechnicianIdToDelete] = useState('');
  const [accessoryIdToDelete, setAccessoryIdToDelete] = useState(''); // Add this state
  const [accessoryMap, setAccessoryMap] = useState({}); // Map for accessory names
  const [technicianMap, setTechnicianMap] = useState({}); // Map for technician names
  const [searchTerm, setSearchTerm] = useState(''); // State for search term

    const fetchInventory = async () => {
      try {
        const access_token = localStorage.getItem('token');
        if (!access_token) {
          throw new Error('No token found');
        }

        // Fetch inventory data
        const response = await axios.get(`${ApiEndpoints.BASE_URL}${ApiEndpoints.TECHNICIAN_INVENTORY}/list`, {
          headers: { Authorization: `Bearer ${access_token}` }
        });

        if (response.status === 200) {
          const data = Array.isArray(response.data) ? response.data : [response.data];

          // Create maps for technician and accessory names
          const technicianMap = {};
          const accessoryMap = {};

          data.forEach(item => {
            if (item.technician) {
              technicianMap[item.technician.id] = item.technician.name;
            }
            item.accessories.forEach(accItem => {
              accessoryMap[accItem.id] = accItem.name;
            });
          });

          setTechnicianMap(technicianMap);
          setAccessoryMap(accessoryMap);

          const groupedInventory = data.reduce((acc, item) => {
            const technicianName = item.technician ? technicianMap[item.technician.id] : 'N/A';
            if (!acc[technicianName]) {
              acc[technicianName] = {};
            }
            item.accessories.forEach(accItem => {
              const accessoryKey = accItem.id; // Use ID here
              if (!acc[technicianName][accessoryKey]) {
                acc[technicianName][accessoryKey] = 0;
              }
              acc[technicianName][accessoryKey] += accItem.quantity || 0;
            });
            return acc;
          }, {});

          const formattedData = Object.entries(groupedInventory).map(([technicianName, accessories]) => ({
            technicianName,
            accessories: Object.entries(accessories).map(([id, quantity]) => ({
              id,
              name: accessoryMap[id], // Add the accessory name here
              quantity
            }))
          }));

          setInventory(formattedData);
          setLoading(false);
        } else {
          throw new Error('Unexpected response status');
        }
      } catch (error) {
        console.error('Error fetching inventory:', error);
        setError('Error fetching inventory. Please try again later.');
        setLoading(false);
      }
    };

    useEffect(() => {
      fetchInventory();
    }, []);

  

  const handleQuantityChange = async (technicianName, accessoryId, change) => {
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      const updatedInventory = inventory.map(item => {
        if (item.technicianName === technicianName) {
          const updatedAccessories = item.accessories.map(acc => {
            if (acc.id === accessoryId) {
              const newQuantity = acc.quantity + change;
              return { ...acc, quantity: Math.max(newQuantity, 0) };
            }
            return acc;
          });

          return { ...item, accessories: updatedAccessories };
        }
        return item;
      });

      setInventory(updatedInventory);

      const apiUrl = `${ApiEndpoints.BASE_URL}${ApiEndpoints.TECHNICIAN_INVENTORY}`;
      await axios.put(apiUrl, {
        technicianName,
        accessories: updatedInventory.find(item => item.technicianName === technicianName).accessories
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'application/json'
        }
      });

      toast.success('Quantity updated successfully!');
    } catch (error) {
      console.error('Error updating quantity:', error);
      toast.error('Error updating quantity. Please try again later.');
    }
  };

  const handleAddInventoryItem = async (event) => {
    event.preventDefault();

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      const { technicianId, accessoryId, quantity } = newInventoryItem;

      const existingInventory = inventory.find(item => item.technicianName === technicianMap[technicianId]);
      let newAccessories = [];
      if (existingInventory) {
        newAccessories = existingInventory.accessories;
      }

      const existingAccessory = newAccessories.find(acc => acc.id === accessoryId);
      if (existingAccessory) {
        existingAccessory.quantity += parseInt(quantity, 10);
      } else {
        newAccessories.push({
          id: accessoryId,
          name: accessoryMap[accessoryId],
          quantity: parseInt(quantity, 10)
        });
      }

      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.TECHNICIAN_INVENTORY}`,
        newInventoryItem,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        const updatedInventory = inventory.map(item => {
          if (item.technicianName === technicianMap[technicianId]) {
            return {
              ...item,
              accessories: newAccessories
            };
          }
          return item;
        });

        setInventory(updatedInventory);
        console.log('Updated inventory:', updatedInventory); // Debugging
        setShowAddPopup(false);
        toast.success('Inventory item added successfully!');
        fetchInventory();
      } else {
        console.error('Failed to add inventory item:', response.data);
        toast.error('Failed to add inventory item. Please try again.');
      }
    } catch (error) {
      console.error('Error adding inventory item:', error);
      setError('Error adding inventory item. Please try again later.');
      toast.error('Error adding inventory item. Please try again.');
    }
  };


  const handleDeleteClick = (technicianId, accessoryId) => {
    setTechnicianIdToDelete(Object.keys(technicianMap).find(key => technicianMap[key] === technicianId));
    setAccessoryIdToDelete(accessoryId);
    setDeleteConfirmationVisible(true);
  };

  const handleConfirmDelete = async () => {
    setDeleteConfirmationVisible(false);

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      const apiUrl = `${ApiEndpoints.BASE_URL}${ApiEndpoints.TECHNICIAN_INVENTORY}/${technicianIdToDelete}/${accessoryIdToDelete}`;

      const response = await axios.delete(apiUrl, {
        headers: { Authorization: `Bearer ${access_token}` }
      });

      if (response.status === 200) {
        toast.success('Inventory item deleted successfully!');
        const updatedInventory = inventory.map(item => {
          if (item.technicianName === technicianMap[technicianIdToDelete]) {
            return {
              ...item,
              accessories: item.accessories.filter(acc => acc.id !== accessoryIdToDelete)
            };
          }
          return item;
        }).filter(item => item.accessories.length > 0);

        setInventory(updatedInventory);
      } else {
        throw new Error('Failed to delete inventory item');
      }
    } catch (error) {
      console.error('Error deleting inventory item:', error);
      toast.error('Error deleting inventory item. Please try again later.');
    }
  };

  const handleViewClick = (item) => {
    const itemDetails = {
      technicianName: item.technicianName,
      accessories: item.accessories
    };

    setSelectedItem(itemDetails);
  };

  const handleAddInventoryClick = () => {
    setNewInventoryItem({
      technicianId: '',
      accessoryId: '',
      quantity: ''
    });
    setShowAddPopup(true);
  };

  const handleClosePopup = () => {
    setShowAddPopup(false);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };


  // Filter inventory based on search term
  const filteredInventory = inventory.filter(item => {
    const technicianMatch = item.technicianName.toLowerCase().includes(searchTerm.toLowerCase());
    const accessoryMatch = item.accessories.some(acc => acc.name.toLowerCase().includes(searchTerm.toLowerCase()));
    return technicianMatch || accessoryMatch;
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <ToastContainer />
      <Navbar />
      <div className="inventory-list-container">
        <div className="inventory-list-actions">
          <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-inputIn"
          />
          <h1>Technician Inventory</h1>
          <button className="add-inventory-button" onClick={handleAddInventoryClick}>
            Add Inventory
          </button>
        </div>
        <div className="table-responsive">
          <table className="inventory-list-table">
            <thead>
              <tr>
                <th>Technician Name</th>
                <th>Accessories Details</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredInventory.map((item, index) => (
                <tr key={index}>
                  <td>{item.technicianName}</td>
                  <td>
                    <ul>
                      {item.accessories.map((acc) => (
                        <li key={acc.id}>
                          Accessory Name: {acc.name}, Quantity: {acc.quantity}
                          <IconButton
                            onClick={() => handleQuantityChange(item.technicianName, acc.id, 1)}
                          >
                            <AddIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleQuantityChange(item.technicianName, acc.id, -1)}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td>
                    {item.accessories.map((acc) => (
                      <div key={acc.id} className="actions">
                        <button
                          className="delete-button"
                          onClick={() => handleDeleteClick(item.technicianName, acc.id)}
                          title="Delete"
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                    <button className='View' onClick={() => handleViewClick(item)}>View</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>


        {/* Add Inventory Popup */}
        {showAddPopup && (
  <div className="inventory-popup">
    <div className="inventory-popup-inner">
      <h2>Add Inventory Item</h2>
      <form onSubmit={handleAddInventoryItem}>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="technicianSearch">Technician Name:</label>
            <TechnicianSearchDropdown
              onTechnicianSelect={(technician) =>
                setNewInventoryItem({
                  ...newInventoryItem,
                  technicianId: technician.id,
                  technicianName: technician.name,
                })
              }
              technicianName={newInventoryItem.technicianName}
            />
          </div>

          <div className="form-group">
            <label htmlFor="accessorySearch">Accessory Name:</label>
            <AccessorySearchDropdown
              onAccessorySelect={(accessory) =>
                setNewInventoryItem({
                  ...newInventoryItem,
                  accessoryId: accessory.id,
                  accessoryName: accessory.name,
                })
              }
              accessoryName={newInventoryItem.accessoryName}
            />
          </div>

          <div className="form-group">
            <label htmlFor="quantity">Quantity:</label>
            <input
              type="number"
              id="quantity"
              value={newInventoryItem.quantity || ''}
              onChange={(e) =>
                setNewInventoryItem({ ...newInventoryItem, quantity: e.target.value })
              }
              required
            />
          </div>
        </div>
        <button type="submit">Add Inventory</button>
        <button type="button" onClick={handleClosePopup}>Cancel</button>
      </form>
    </div>
  </div>
)}



        {/* View Details Modal */}
        {selectedItem && (
          <InventoryModal
            item={selectedItem}
            onClose={handleCloseModal}
            show={Boolean(selectedItem)}
          />
        )}

        {/* Delete Confirmation Modal */}
        {deleteConfirmationVisible && (
          <div className="delete-confirmation-modal">
            <h2>Are you sure you want to delete this item?</h2>
            <button className='Yes' onClick={handleConfirmDelete}>Yes</button>
            <button className='No' onClick={() => setDeleteConfirmationVisible(false)}>No</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Inventory;
