// src/pages/EmployeePage/EmployeePage.js
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import {jwtDecode} from 'jwt-decode';
import '../../styles/EmployeePage.css';

const EmployeePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const access_token = localStorage.getItem('token');
    if (!access_token) {
      navigate('/login');
    } else {
      try {
        jwtDecode(access_token);
      } catch (error) {
        console.error('Invalid token:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        navigate('/login');
      }
    }
  }, [navigate]);

  const cards = [
    { title: 'Product List', path: '/productlist' },
    { title: 'Inventory', path: '/inventory' },
    // { title: 'Employee List', path: '/employeelist' }, // Updated path to '/employeelist'
    { title: 'Technician List', path: '/technicianlist' },
    { title: 'Sales Order History', path: '/saleshistory' },
    { title: 'Service Order History', path: '/servicehistory' },
    { title: 'Customer List', path: '/customer' },
    { title: 'Accessories List', path: '/accessorieslist' },
    { title: 'Service Request', path: '/servicerequest' },
  ];

  return (
    <div>
      <Navbar />
      <div className="employeepage2">
        <div className="card-container2">
          {cards.map((card, index) => (
            <Link key={index} to={card.path} className="card-link2">
              <div className="card2">
                <h3>{card.title}</h3>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmployeePage;
