import React from 'react';
import '../../styles/InventoryModal.css'; // Adjust path to CSS file as needed

const InventoryModal = ({ item, onClose, show }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="details-modal-overlay">
      <div className="details-modal-container">
        <h2>Inventory Item Details</h2>
        {item ? (
          <>
            <p><strong>Technician Name:</strong> {item.technicianName}</p>
            {/* <h3>Accessories:</h3> */}
            <ul>
              {item.accessories.map((accessory, index) => (
                <li key={index}>
                  <p><strong>Accessory Name:</strong> {accessory.name}<br /></p>
                  <p><strong>Quantity:</strong> {accessory.quantity}</p>
                </li>
              ))}
            </ul>
            <button onClick={onClose} className="close-button">Close</button>
          </>
        ) : (
          <p>No item details available.</p>
        )}
      </div>
    </div>
  );
};

export default InventoryModal;
// 