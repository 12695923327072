import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import '../../styles/ProductPage.css';
import Navbar from '../Navbar/Navbar';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';

const ProductPage = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const access_token = localStorage.getItem('token');
        if (!access_token) {
          throw new Error('No token found');
        }

        const response = await axios.get(
          `${ApiEndpoints.BASE_URL}${ApiEndpoints.PRODUCTID_URL}${productId}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product details:', error);
        setError('Error fetching product details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  const handleNextImage = () => {
    if (currentImageIndex < product.images.length - 1) {
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePreviousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !product) {
    return <div>Error: {error || 'Product not found.'}</div>;
  }

  return (
    <div>
      <Navbar />
      <div className="product-details">
        <h1>{product.name}</h1>
        <div className="product-image-container">
          <FaChevronLeft 
            onClick={handlePreviousImage} 
            className={`image-nav-icon prev-icon ${currentImageIndex === 0 ? 'disabled' : ''}`}
            disabled={currentImageIndex === 0}
          />
          <img
            src={ApiEndpoints.BASEURL_IMAGE + product.images[currentImageIndex]}
            alt={`${product.name} ${currentImageIndex + 1}`}
            className="product-image"
          />
          <FaChevronRight 
            onClick={handleNextImage} 
            className={`image-nav-icon next-icon ${currentImageIndex === product.images.length - 1 ? 'disabled' : ''}`}
            disabled={currentImageIndex === product.images.length - 1}
          />
        </div>
        <p><b>Description:</b>{product.description}</p>
        <p><b>Price:</b>{product.price}</p>
        {/* <p>Available Quantity: {product.quantity}</p> */}
        {/* <button className="buy-now-button">Buy Now</button> */}
      </div>
    </div>
  );
};

export default ProductPage;
