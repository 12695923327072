// src/pages/TechnicianPage/TechnicianPage.js
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import '../../styles/TechnicianPage.css';
import Navbar from '../Navbar/Navbar';

const TechnicianPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const access_token = localStorage.getItem('token');
    if (!access_token) {
      navigate('/login');
    } else {
      try {
        jwtDecode(access_token);
      } catch (error) {
        console.error('Invalid token:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        navigate('/login');
      }
    }
  }, [navigate]);

  const cards = [
    { title: 'Product List', path: '/productlist' },
    { title: 'Inventory', path: '/inventory' },
    { title: 'Employee List', path: '/employeelist' },
    { title: 'Technician List', path: '/technicianlist' },
    { title: 'Sales Order History', path: '/saleshistory' },
    { title: 'Service Order History', path: '/servicehistory' },
    { title: 'Customer List', path: '/customer' },
  ];

  return (
    <div>
      <Navbar/>
    <div className="techniciapage3">
      <div className="card-container3">
        {cards.map((card, index) => (
          <Link key={index} to={card.path} className="card-link3">
            <div className="card3">
              <h3>{card.title}</h3>
            </div>
          </Link>
        ))}
      </div>
    </div>
    </div>
  );
};

export default TechnicianPage;
