// src/pages/Wishlist/Wishlist.js
import React, { useState, useEffect } from 'react';
import '../../styles/Wishlist.css';
import Navbar from '../Navbar/Navbar';


const Wishlist = () => {
  const [wishlistItems, setWishlistItems] = useState([]);

  // Simulated initial data
  useEffect(() => {
    // Fetch wishlist data from API or local storage
    const initialData = [
      { id: 1, name: 'Premium Headphones', image: '/images/headphones.jpg', price: 129.99 },
      { id: 2, name: 'Smart Watch', image: '/images/smartwatch.jpg', price: 199.99 },
      { id: 3, name: 'Wireless Speaker', image: '/images/speaker.jpg', price: 79.99 },
    ];
    setWishlistItems(initialData);
  }, []);

  const removeFromWishlist = (id) => {
    const updatedItems = wishlistItems.filter(item => item.id !== id);
    setWishlistItems(updatedItems);
  };

  return (
    <div className='nav'>
        <Navbar/>
    <div className="wishlist">
      <h1>My Wishlist</h1>
      <div className="wishlist-items">
        {wishlistItems.length === 0 ? (
          <p>Your wishlist is empty.</p>
        ) : (
          <div className="wishlist-list">
            {wishlistItems.map(item => (
              <div key={item.id} className="wishlist-item">
                <img src={item.image} alt={item.name} className="wishlist-item-image" />
                <div className="wishlist-item-details">
                  <p className="wishlist-item-name">{item.name}</p>
                  <p className="wishlist-item-price">${item.price.toFixed(2)}</p>
                  <button className="wishlist-item-remove" onClick={() => removeFromWishlist(item.id)}>Remove</button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default Wishlist;

