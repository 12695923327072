import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/SalesHistory.css';
import Navbar from '../Navbar/Navbar';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import UserSearchDropdown from '../UserSearchDropdown/UserSearchDropdown';

const SalesHistory = () => {
  const [salesHistory, setSalesHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('ALL');
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    page: '',
    size: '',
    totalElements: '',
    totalPages: '',
  });
  const [userName, setUserName] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [showSearchBar, setShowSearchBar] = useState(false);

  useEffect(() => {
    const fetchSalesHistory = async () => {
      try {
        const access_token = localStorage.getItem('token');
        if (!access_token) {
          throw new Error('No token found');
        }

        let url = '';

        if (filter === 'Accessory') {
          url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.POS_URL}/list?page=${pagination.page}&size=${pagination.size}&type=accessory`;
          if (showSearchBar && selectedUser) {
            url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.POS_URL}/user/${selectedUser.id}?page=${pagination.page}&size=${pagination.size}&type=accessory`;
          }
        } else if (filter === 'Product') {
          url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.POS_URL}/list?page=${pagination.page}&size=${pagination.size}&type=product`;
          if (showSearchBar && selectedUser) {
            url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.POS_URL}/user/${selectedUser.id}?page=${pagination.page}&size=${pagination.size}&type=product`;
          }
        } else if (filter === 'ALL') {
          url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.POS_URL}/list?page=${pagination.page}&size=${pagination.size}&type=all`;
          if (showSearchBar && selectedUser) {
            url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.POS_URL}/user/${selectedUser.id}?page=${pagination.page}&size=${pagination.size}&type=all`;
          }
        }

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json',
          },
        });

        setSalesHistory(response.data.content);
        setPagination({
          hasNextPage: response.data.hasNextPage,
          hasPreviousPage: response.data.hasPreviousPage,
          page: response.data.page,
          size: response.data.size,
          totalElements: response.data.totalElements,
          totalPages: response.data.totalPages,
        });
      } catch (error) {
        setError(error.response ? error.response.data.message : error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSalesHistory();
  }, [pagination.page, pagination.size, filter, selectedUser, showSearchBar]);

  const handlePageChange = (event, value) => {
    console.log('Selected page:', value);
    setPagination((prev) => ({
      ...prev,
      page: value,
    }));
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleRadioChange = (event) => {
    setShowSearchBar(event.target.value === 'yes');
  };

  const handleUserSelection = (user) => {
    setSelectedUser(user);
    setUserName(user.name);
  };



  const startingSerialNumber = (pagination.page - 1) * pagination.size + 1;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="sales-history-page">
      <Navbar />
      <div className="sales-history-container">
        <h2>Sales Order History</h2>
        <div className="filter-container">
          <div className="filter-dropdown">
            <label htmlFor="filter">Filter by:</label>
            <select id="filter" value={filter} onChange={handleFilterChange}>
              <option value="ALL">All</option>
              <option value="Accessory">Accessory</option>
              <option value="Product">Product</option>
            </select>
          </div>
          <div className="radio-buttons">
            <label htmlFor="filter">Filter by Customer:</label>
            <label>
              <input
                type="radio"
                value="yes"
                checked={showSearchBar === true}
                onChange={handleRadioChange}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                value="no"
                checked={showSearchBar === false}
                onChange={handleRadioChange}
              />
              No
            </label>
          </div>
          {showSearchBar && (
            <div className="user-search-dropdown">
              <div className="search-bar-wrapper">
                <UserSearchDropdown
                  onUserSelect={handleUserSelection}
                  userName={userName}
                />
              </div>
            </div>
          )}
        </div>
        <div className="table-responsive">
          <table className="sales-history-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Order ID</th>
                <th>Customer Name</th>
                <th>Name</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Installation Date</th>
                <th>Warranty Expiry</th>
                <th>Item Type</th>
              </tr>
            </thead>
            <tbody>
              {salesHistory.map((order, index) => (
                <tr key={order.id}>
                  <td>{startingSerialNumber + index}</td>
                  {filter === 'ALL' && (
                    <>
                      <td>{order.accessory ? order.accessory.id : order.product ? order.product.id : 'N/A'}</td>
                      <td>{order.accessory ? order.customer.name : order.product ? order.customer.name : 'N/A'}</td>
                      <td>{order.accessory ? order.accessory.name : order.product ? order.product.name : 'N/A'}</td>
                      <td>{order.accessory ? order.accessory.price : order.product ? order.product.price : 'N/A'}</td>
                      <td>{order.quantity}</td>
                      <td>{order.installationDate ? new Date(order.installationDate).toLocaleString() : 'N/A'}</td>
                      <td>{order.warrantyExpiry ? new Date(order.warrantyExpiry).toLocaleString() : 'N/A'}</td>
                      <td>{order.accessory ? 'Accessory' : order.product ? 'Product' : 'N/A'}</td>
                      {/* <td>{order.accessory ? order.accessory.itemType : order.product ? order.product.itemType : 'N/A'}</td> */}
                    </>
                  )}
                  {filter !== 'Product' && filter !== 'ALL' && (
                    <>
                      <td>{order.accessory ? order.accessory.id : 'N/A'}</td>
                      <td>{order.accessory ? order.customer.name : 'N/A'}</td>
                      <td>{order.accessory ? order.accessory.name : 'N/A'}</td>
                      <td>{order.accessory ? order.accessory.price : 'N/A'}</td>
                      <td>{order.quantity}</td>
                      <td>{order.installationDate ? new Date(order.installationDate).toLocaleString() : 'N/A'}</td>
                      <td>{order.warrantyExpiry ? new Date(order.warrantyExpiry).toLocaleString() : 'N/A'}</td>
                      <td>{order.accessory ? 'Accessory' : 'N/A'}</td>
                    </>
                  )}
                  {filter !== 'Accessory' && filter !== 'ALL' && (
                    <>
                      <td>{order.product ? order.product.id : 'N/A'}</td>
                      <td>{order.product ? order.customer.name : 'N/A'}</td>
                      <td>{order.product ? order.product.name : 'N/A'}</td>
                      <td>{order.product ? order.product.price : 'N/A'}</td>
                      <td>{order.quantity}</td>
                      <td>{order.installationDate ? new Date(order.installationDate).toLocaleString() : 'N/A'}</td>
                      <td>{order.warrantyExpiry ? new Date(order.warrantyExpiry).toLocaleString() : 'N/A'}</td>
                      <td>{order.product ? 'Product' : 'N/A'}</td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination1">
          <Stack spacing={2}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.page}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: () => <span>Previous</span>,
                    next: () => <span>Next</span>,
                  }}
                  {...item}
                  selected={item.page === pagination.page}
                />
              )}
            />
          </Stack>
        </div>


      </div>
    </div>
  );
};

export default SalesHistory;