import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/EmployeeList.css'; 
import Navbar from '../Navbar/Navbar';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmployeeList = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editEmployeeData, setEditEmployeeData] = useState({});
  const [newEmployeeData, setNewEmployeeData] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    password: '',
    role: 'MANAGER'
  });

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }
      const response = await axios.get(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}?user_type=MANAGER`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      setEmployees(response.data || []);
      setLoading(false);
    } catch (error) {
      setError('Error fetching employees. Please try again later.');
      setLoading(false);
    }
  };

  const handleEditClick = (employee) => {
    setEditEmployeeData(employee);
    setShowEditPopup(true);
  };

  const handleEditEmployee = async (event) => {
    event.preventDefault();
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }
      const response = await axios.put(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}/${editEmployeeData.id}`,
        editEmployeeData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.status === 200) {
        fetchEmployees(); // Refetch employees to include the edited employee
        setShowEditPopup(false);
        toast.success('Employee updated successfully!');
      } else {
        console.error('Failed to edit employee:', response);
      }
    } catch (error) {
      setError('Error editing employee. Please try again later.');
    }
  };

  const handleDeleteClick = (employee) => {
    setEmployeeToDelete(employee);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setShowModal(false);
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }
      const response = await axios.delete(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}/${employeeToDelete.id}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );
      if (response.status === 200) {
        fetchEmployees(); // Refetch employees to remove the deleted employee
        toast.success('Employee deleted successfully!');
      } else {
        console.error('Failed to delete employee:', response);
        throw new Error('Failed to delete employee');
      }
    } catch (error) {
      setError('Error deleting employee. Please try again later.');
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
  };

  const handleAddEmployee = async (event) => {
    event.preventDefault();
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }
      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.USER_TYPE}`,
        newEmployeeData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.status === 200) {
        fetchEmployees(); // Refetch employees to include the new employee
        setShowAddPopup(false);
        toast.success('Employee added successfully!');
      } else {
        console.error('Failed to add employee:', response.data);
      }
    } catch (error) {
      setError('Error adding employee. Please try again later.');
    }
  };

  const handleAddEmployeeClick = () => {
    setNewEmployeeData({
      name: '',
      email: '',
      mobileNumber: '',
      password: '',
      role: 'MANAGER'
    });
    setShowAddPopup(true);
  };

  const handleClosePopup = () => {
    setShowAddPopup(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredEmployees = employees.filter(
    (employee) => employee && employee.name && employee.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <ToastContainer />
      <Navbar />
      <div className="employee-list-container10">
        <div className="employee-list-actions10">
          <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input10"
          />
          <h1>Employee List</h1>
          <button className="add-employee-button10" onClick={handleAddEmployeeClick}>Add Employee</button>
        </div>

        <div className="table-responsive">
        <table className="employee-list-table10">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile number</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredEmployees.map((employee) => (
              <tr key={employee.id}>
                <td>{employee.id}</td>
                <td>{employee.name}</td>
                <td>{employee.email}</td>
                <td>{employee.mobileNumber}</td>
                <td>{employee.role}</td> 
                {/* <td>{employee.password}</td> */}
                
                <td>
                  <div className="action-buttons-container10">
                    <button className="edit-button10" onClick={() => handleEditClick(employee)}>Edit</button>
                    <button className="delete-button10" onClick={() => handleDeleteClick(employee)}>Delete</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>

        {showAddPopup && (
          <div className="popup10">
            <div className="popup-inner10">
              <h2>Add Employee</h2>
              <form onSubmit={handleAddEmployee}>
              <div className="form-group10">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  value={newEmployeeData.name || ''}
                  onChange={(e) =>
                    setNewEmployeeData({ ...newEmployeeData, name: e.target.value })
                  }
                  required
                />
                </div>

                <div className="form-group10">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  value={newEmployeeData.email || ''}
                  onChange={(e) =>
                    setNewEmployeeData({ ...newEmployeeData, email: e.target.value })
                  }
                  required
                />
                </div>

                <div className="form-group10">
                <label htmlFor="mobileNumber">Mobile Number:</label>
                <input
                  type="text"
                  id="mobileNumber"
                  value={newEmployeeData.mobileNumber || ''}
                  onChange={(e) =>
                    setNewEmployeeData({ ...newEmployeeData, mobileNumber: e.target.value })
                  }
                  required
                  pattern="\d{10}"
                  maxLength="10"
                />
                </div>

                <div className="form-group10">
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  value={newEmployeeData.password || ''}
                  onChange={(e) =>
                    setNewEmployeeData({ ...newEmployeeData, password: e.target.value })
                  }
                  required
                  pattern=".{8,}"
                  title="Password must be at least 8 characters long."
                />
                </div>

                <div className="button-container10">
                  <button type="button" className="cancel-button10" onClick={handleClosePopup}>Cancel</button>
                  <button type="submits" className="save-button10">Add Employee</button>
                  <button className="close-button10" onClick={handleClosePopup}>Close</button>
                </div>
                
              </form>
            </div>
          </div>
        )}

        {showEditPopup && (
          <div className="popup10">
            <div className="popup-inner10">
              <h2>Edit Employee</h2>
              <form onSubmit={handleEditEmployee}>

              <div className="form-group10">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  value={editEmployeeData.name || ''}
                  onChange={(e) =>
                    setEditEmployeeData({ ...editEmployeeData, name: e.target.value })
                  }
                  required
                />
                </div>

                <div className="form-group10">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  value={editEmployeeData.email || ''}
                  onChange={(e) =>
                    setEditEmployeeData({ ...editEmployeeData, email: e.target.value })
                  }
                  required
                />
                </div>

                <div className="form-group10">
                <label htmlFor="mobileNumber">Mobile Number:</label>
                <input
                  type="text"
                  id="mobileNumber"
                  value={editEmployeeData.mobileNumber || ''}
                  onChange={(e) =>
                    setEditEmployeeData({ ...editEmployeeData, mobileNumber: e.target.value })
                  }
                  required
                  pattern="\d{10}"
                  maxLength="10"
                  title="Please enter a valid 10-digit mobile number."
                />
                </div>
                <div className="button-container10">
                  <button type="button" className="cancel-button10" onClick={() => setShowEditPopup(false)}>Cancel</button>
                  <button type="submits" className="save-button10">Save Changes</button>
                  <button className="close-button10" onClick={() => setShowEditPopup(false)}>Close</button>
                </div>
              </form>
            </div>
          </div>
        )}

        {showModal && employeeToDelete && (
          <div className="modal-overlayc">
            <div className="modal-contentc">
              <h2>Confirm Deletion</h2>
              <p>Are you sure you want to delete employee {employeeToDelete.name}?</p>
              <div className="button-containerc">
                <button className='Confirm' onClick={handleCancelDelete}>No</button>
                <button className='Cancel' onClick={handleConfirmDelete}>Yes</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeList;
