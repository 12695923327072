import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import '../../styles/ProductListAdmin.css';
// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles
import 'react-resizable/css/styles.css'; // Import resizable styles
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProductListAdmin = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editFormData, setEditFormData] = useState({
    name: '',
    price: '',
    description: '',
    thumbnail: null,
    images: [],
  });
  const [newProductData, setNewProductData] = useState({
    name: '',
    price: '',
    description: '',
    thumbnail: null,
    images: [],
  });

  const [previewImage, setPreviewImage] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const access_token = localStorage.getItem('token');
        if (!access_token) {
          throw new Error('No token found');
        }

        const response = await axios.get(
          `${ApiEndpoints.BASE_URL}${ApiEndpoints.PRODUCTLIST_URL}/list`,
          { headers: { Authorization: `Bearer ${access_token}` } }
        );

        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Error fetching products. Please try again later.');
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  const handleEditClick = (product) => {
    console.log('Editing product:', product);

    setEditFormData(product);

    const thumbnailUrl = ApiEndpoints.BASEURL_IMAGE + product.thumbnail;
    setPreviewImage(thumbnailUrl);
    console.log('Thumbnail URL:', thumbnailUrl);

    const imagesUrls = product.images.map(image => ApiEndpoints.BASEURL_IMAGE + image);
    setPreviewImages(imagesUrls);
    console.log('Images URLs:', imagesUrls);

    setShowEditPopup(true);
  };

  const handleEditProduct = async (event) => {
    event.preventDefault();

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      // Handle thumbnail
      let imageUrl = editFormData.thumbnail;
      if (editFormData.thumbnail instanceof File) {
        imageUrl = await uploadImageAndGetURL(editFormData.thumbnail, access_token);
      }

      // Handle images
      let imagesUrls = editFormData.images
        .filter(image => typeof image === 'string' && !image.startsWith('deleted'));

      if (editFormData.images.some(file => file instanceof File)) {
        const newImagesUrls = await Promise.all(
          editFormData.images
            .filter(file => file instanceof File)
            .map(file => uploadImageAndGetURL(file, access_token))
        );
        imagesUrls = [...imagesUrls, ...newImagesUrls];
      }

      const productData = {
        name: editFormData.name,
        price: editFormData.price,
        description: editFormData.description,
        thumbnail: imageUrl,
        images: imagesUrls,
      };

      const response = await axios.put(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.CREATE_PRODUCT_URL}/${editFormData.id}`,
        productData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        // Update products state with the updated product data
        const updatedProducts = products.map(product =>
          product.id === editFormData.id ? { ...productData, id: product.id } : product
        );
        setProducts(updatedProducts);
        setShowEditPopup(false);
        toast.success('Product edited successfully!');
      } else {
        console.error('Failed to edit product:', response);
      }
    } catch (error) {
      console.error('Error editing product:', error);
      setError('Error editing product. Please try again later.');
    }
  };

  const handleDeleteClick = (product) => {
    setProductIdToDelete(product);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setShowModal(false);

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        console.error('No token found');
        throw new Error('No token found');
      }

      console.log('Attempting to delete product with ID:', productIdToDelete.id);

      const response = await axios.delete(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.CREATE_PRODUCT_URL}/${productIdToDelete.id}`,
        { headers: { Authorization: `Bearer ${access_token}` } }
      );

      console.log('Delete request response:', response);

      if (response.status === 200) {
        console.log('Product deleted successfully:', productIdToDelete.id);
        const updatedProducts = products.filter(product => product.id !== productIdToDelete.id);
        setProducts(updatedProducts);
        toast.success('Product deleted successfully!');
      } else {
        console.error('Failed to delete product:', response);
        throw new Error('Failed to delete product');
      }
    } catch (error) {
      console.error('Error deleting product:', error);
      setError('Error deleting product. Please try again later.');
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
  };

  const uploadImageAndGetURL = async (imageFile, access_token) => {
    try {
      console.log('Uploading image:', imageFile);

      const formData = new FormData();
      formData.append('file', imageFile);

      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.PRODUCT_THUMBNAIL_UPLOAD_URL}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      console.log('Upload response:', response); // Debugging: Check the response

      if (response.status === 200) {
        const imageUrl = response.data.file_path;
        console.log('Image uploaded successfully. Image URL:', imageUrl);
        return imageUrl;
      } else {
        console.error('Failed to upload image:', response);
        throw new Error('Failed to upload image');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      throw new Error('Error uploading image');
    }
  };

  const handleAddProduct = async (event) => {
    event.preventDefault();

    try {
      const accessToken = localStorage.getItem('token');
      if (!accessToken) throw new Error('No token found');

      let imageUrl = null;
      let imagesUrls = [];

      if (newProductData.thumbnail instanceof File) {
        imageUrl = await uploadImageAndGetURL(newProductData.thumbnail, accessToken);
      }

      if (newProductData.images.length > 0) {
        imagesUrls = await Promise.all(
          newProductData.images.map(file => uploadImageAndGetURL(file, accessToken))
        );
      }

      const productData = {
        name: newProductData.name,
        price: newProductData.price,
        description: newProductData.description,
        thumbnail: imageUrl,
        images: imagesUrls,
      };

      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.PRODUCTLIST_URL}`,
        productData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setProducts([...products, response.data]);
        setShowAddPopup(false);
        toast.success('Product added successfully!');
      } else {
        throw new Error('Failed to add product');
      }
    } catch (error) {
      console.error('Error adding product:', error.response ? error.response.data : error.message);
      setError('Error adding product. Please try again later.');
    }
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setNewProductData({ ...newProductData, thumbnail: file });

    // Preview image
    const imageUrl = URL.createObjectURL(file);
    setPreviewImage(imageUrl);
  };



  const handleThumbnailChanges = (e) => {
    const file = e.target.files[0];
    setEditFormData({ ...editFormData, thumbnail: file });

    // Preview image
    const imageUrl = URL.createObjectURL(file);
    setPreviewImage(imageUrl);
  };

  const deleteThumbnail = async (imageUrl) => {
    try {
      const access_token = localStorage.getItem('token');
      const fileName = imageUrl.replace(ApiEndpoints.BASEURL_IMAGE, '');
      if (!access_token) {
        throw new Error('No token found');
      }

      // Call to delete the image from server
      const response = await axios.delete(`${ApiEndpoints.BASE_URL}${ApiEndpoints.DELETE_IMAGE_URL}?fileName=${fileName}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'application/json'
        },
      });

      if (response.status === 200) {
        setPreviewImage(null);
        setEditFormData({
          ...editFormData,
          thumbnail: 'deleted' // Mark the image as deleted
        });
        toast.success('Image deleted successfully');
      } else {
        throw new Error('Failed to delete image');
      }
    } catch (error) {
      console.error('Error deleting thumbnail:', error);
      toast.error('Error deleting thumbnail');
    }
  };


  const handleAddProductClick = () => {
    // Reset newCustomerData before showing the popup
    setNewProductData({
      name: '',
      price: '',
      description: '',
      thumbnail: null,
      images: []
    });
    setPreviewImage(null);
    setPreviewImages([]);
    setShowAddPopup(true);
  };

  // Handle close popup
  const handleClosePopup = () => {
    setShowAddPopup(false);
  };

  const handleImagesChange = (e) => {
    const files = Array.from(e.target.files);
    setNewProductData({ ...newProductData, images: [...newProductData.images, ...files] });

    // Preview images
    const imagesUrls = files.map(file => URL.createObjectURL(file));
    setPreviewImages([...previewImages, ...imagesUrls]);
  };

  const handleImagesChanges = (e) => {
    const files = Array.from(e.target.files);

    // Update the state with new files
    setEditFormData(prevData => ({
      ...prevData,
      images: [...prevData.images, ...files] // Append new files
    }));

    // Generate previews for the new files
    const newImagesUrls = files.map(file => URL.createObjectURL(file));
    setPreviewImages(prevImages => [...prevImages, ...newImagesUrls]);
  };

  const deleteImage = async (index) => {
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      const imageToDelete = editFormData.images[index];
      const fileName = imageToDelete.replace(ApiEndpoints.BASEURL_IMAGE, '');

      await axios.delete(`${ApiEndpoints.BASE_URL}${ApiEndpoints.DELETE_IMAGE_URL}?fileName=${fileName}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'application/json',
        },
      });

      // Remove the image from preview and state
      const updatedPreviewImages = [...previewImages];
      updatedPreviewImages.splice(index, 1);
      setPreviewImages(updatedPreviewImages);

      const updatedImages = [...editFormData.images];
      updatedImages.splice(index, 1);
      setEditFormData({ ...editFormData, images: updatedImages });

    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };



  const filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Navbar />
      <ToastContainer />
      <div className="product-list-admin-container1">
        <div className="product-list-admin-content1">
          {/* <h1 className="product-list-heading1">Product List</h1> */}
          <div className="product-list-actions1">
            <input
              type="text"
              placeholder="Search by name or ID..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <h1 className="product-list-heading1">Product List</h1>

            <button className="add-product-button1" onClick={handleAddProductClick}>Add Product</button>
          </div>

          <div className="table-responsive">
            <table className="product-list-table1">
              <thead>
                <tr className="table-header-row1">
                  <th className="table-header-cell1" style={{ padding: '15px' }}>S.No</th>
                  <th className="table-header-cell1" style={{ padding: '15px' }}>Image</th>
                  <th className="table-header-cell1" style={{ padding: '15px' }}>Product Name</th>
                  <th className="table-header-cell1" style={{ padding: '15px' }}>Price</th>
                  <th className="table-header-cell1" style={{ padding: '15px' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredProducts.map((product, index) => (
                  <tr key={product.id} className="table-row1">
                    <td className="table-cell1">{index + 1}</td>
                    <td className="table-cell1">
                      <Link to={`/productlistadmin/${product.id}`} className="product-link1">
                        <img src={ApiEndpoints.BASEURL_IMAGE + product.thumbnail} alt={product.name} className="product-thumbnail1" />
                      </Link>
                    </td>
                    <td className="table-cell1">{product.name}</td>
                    <td className="table-cell1">{product.price}</td>
                    <td className="table-cell1">
                      <div className="action-buttons-container1">
                        <button className="edit-button1" onClick={() => handleEditClick(product)}>Edit</button>
                        <button className="delete-button1" onClick={() => handleDeleteClick(product)}>Delete</button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {showAddPopup && (
          <div className="popup1">
            <div className="popup-inner1">
              <h2>Add Product</h2>
              <form onSubmit={handleAddProduct} encType="multipart/form-data">
                <div className="form-group">
                  <label htmlFor="name">Product Name:</label>
                  <input
                    type="text"
                    id="name"
                    value={newProductData.name || ''}
                    onChange={(e) =>
                      setNewProductData({ ...newProductData, name: e.target.value })
                    }
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="price">Price:</label>
                  <input
                    type="text"
                    id="price"
                    value={newProductData.price || ''}
                    onChange={(e) =>
                      setNewProductData({ ...newProductData, price: e.target.value })
                    }
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="description1">Description:</label>
                  <div className="resizable-box-content">
                    <textarea
                      value={newProductData.description || ''}
                      onChange={(event) =>
                        setNewProductData({ ...newProductData, description: event.target.value })
                      }
                      placeholder="Enter description here..."
                      className="textarea-container"
                      rows="10"
                    />
                  </div>
                </div>


                <div className="form-group">
                  <label htmlFor="thumbnail">Thumbnail:</label>
                  <input
                    type="file"
                    id="thumbnail"
                    onChange={handleThumbnailChange}
                    accept="image/*"
                    required
                  />
                  {previewImage && (
                    <div className="image-preview-container1">
                      <img src={previewImage} alt="Thumbnail Preview" className="image-preview1" />
                      <button
                        className="delete-image-button1"
                        onClick={() => {
                          setPreviewImage(null);
                          setNewProductData({ ...newProductData, thumbnail: null });
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="images">Images:</label>
                  <input
                    type="file"
                    id="images"
                    onChange={handleImagesChange}
                    accept="image/*"
                    multiple
                  />
                  {previewImages.length > 0 && (
                    <div className="images-preview1">
                      {previewImages.map((imageUrl, index) => (
                        <div key={index} className="image-preview-container1">
                          <img src={imageUrl} alt={`Preview ${index}`} className="image-preview1" />
                          <button
                            className="delete-image-button1"
                            onClick={() => {
                              const updatedPreviewImages = [...previewImages];
                              updatedPreviewImages.splice(index, 1);
                              setPreviewImages(updatedPreviewImages);

                              const updatedImages = [...newProductData.images];
                              updatedImages.splice(index, 1);
                              setNewProductData({ ...newProductData, images: updatedImages });
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="button-container23">
                  <button type="button" className="cancel-button23" onClick={handleClosePopup}>Cancel</button>
                  <button type="submits" className="save-button23">Add Product</button>
                  <button className="close-button23" onClick={handleClosePopup}>Close</button>
                </div>
              </form>
            </div>
          </div>
        )}

        {showEditPopup && (
          <div className="popup1">
            <div className="popup-inner1">
              <h2>Edit Product</h2>
              <form onSubmit={handleEditProduct} encType="multipart/form-data">
                {/* Product Name */}
                <div className="form-group">
                  <label htmlFor="edit-name">Product Name:</label>
                  <input
                    type="text"
                    id="edit-name"
                    value={editFormData.name}
                    onChange={(e) => setEditFormData({ ...editFormData, name: e.target.value })}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="edit-price">Price:</label>
                  <input
                    type="text"
                    id="edit-price"
                    value={editFormData.price}
                    onChange={(e) => setEditFormData({ ...editFormData, price: e.target.value })}
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="description1">Description:</label>
                  <div className="resizable-box-content">
                    <textarea
                      value={editFormData.description || ''}
                      onChange={(event) =>
                        setEditFormData({ ...editFormData, description: event.target.value })
                      }
                      placeholder="Enter description here..."
                      className="textarea-container"
                      rows="10"
                    />
                  </div>
                </div>


                <div className="form-group">
                  <label htmlFor="edit-thumbnail">Thumbnail:</label>
                  <input
                    type="file"
                    id="edit-thumbnail"
                    onChange={handleThumbnailChanges}
                    accept="image/*"
                  />
                  {previewImage && (
                    <div className="image-preview-container1">
                      <img src={previewImage} alt="Thumbnail Preview" className="image-preview1" />
                      <button
                        className="delete-image-button1"
                        type="button"
                        onClick={() => deleteThumbnail(previewImage)}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="edit-images">Images:</label>
                  <input
                    type="file"
                    id="edit-images"
                    onChange={handleImagesChanges}
                    accept="image/*"
                    multiple
                  />
                  {previewImages.length > 0 && (
                    <div className="images-preview1">
                      {previewImages.map((imagesUrls, index) => (
                        <div key={index} className="image-preview-container1">
                          <img src={imagesUrls} alt={` Preview ${index}`} className="image-preview1" />
                          <button
                            className="delete-image-button1"
                            type="button"
                            onClick={() => deleteImage(index)}
                          >
                            Delete
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="button-container23">
                  <button type="button" className="cancel-button23" onClick={() => setShowEditPopup(false)}>Cancel</button>
                  <button type="submits" className="save-button23">Save Changes</button>
                  <button className="close-button23" onClick={() => setShowEditPopup(false)}>Close</button>
                </div>
              </form>
            </div>
          </div>
        )}

        {showModal && (
          <div className="modal-overlayb">
            <div className="modal-contentb">
              <p>Are you sure you want to delete the {productIdToDelete.name}?</p>
              <div className="button-containerb">
                <button className='Confirm' onClick={handleConfirmDelete}>Yes</button>
                <button className='Cancel' onClick={handleCancelDelete}>No</button>
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default ProductListAdmin;
