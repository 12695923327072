import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './components/LoginPage/LoginPage';
import ProductList from './pages/ProductList/ProductList';
import SalesHistory from './pages/SalesHistory/SalesHistory';
import TechnicianList from './pages/TechnicianList/TechnicianList';
import Inventory from './pages/Inventory/Inventory';
import CustomerList from './pages/CustomerList/CustomerList';
import EmployeeList from './pages/EmployeeList/EmployeeList';
import AdminPage from './pages/AdminPage/AdminPage';
import TechnicianPage from './pages/TechnicianPage/TechnicianPage';
import EmployeePage from './pages/EmployeePage/EmployeePage';
import UserPage from './pages/UserPage/UserPage';
import AccessoriesList from './pages/AccessoriesList/AccessoriesList';
import ServiceRequest from './pages/ServiceRequest/ServiceRequest';
import Wishlist from './pages/Wishlist/Wishlist';
import Profile from './pages/Profile/Profile';
import './styles/App.css';
import ProductPage from './pages/ProductPage/ProductPage';
import Access from './pages/Access/Access';
import ProductListAdmin from './pages/ProductListAdmin/ProductListAdmin';
import 'react-toastify/dist/ReactToastify.css';
import AccessoryPage from './pages/AccessoryPage/AccessoryPage';
import ServiceRequestDetails from './pages/ServiceRequestDetails/ServiceRequestDetails';
import PointOfSale from './pages/PointOfSale/PointOfSale';
import ServiceOrderHistory from './pages/ServiceOrderHistory/ServiceOrderHistory';
import ServiceViewHistory from './pages/ServiceViewHistory/ServiceViewHistory';

const App = () => {
  return (
    <div className="App">
      <main>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/user" element={<UserPage />} />
          <Route path="/employee" element={<EmployeePage />} />
          <Route path="/technician" element={<TechnicianPage />} />
          <Route path="/productlist" element={<ProductList />} />
          <Route path="/products/:productId" element={<ProductPage />} />
          <Route path="/saleshistory" element={<SalesHistory />} />
          <Route path="/technicianlist" element={<TechnicianList />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/pointofsale" element={<PointOfSale />} />
          <Route path="/customer" element={<CustomerList />} />
          <Route path="/employeelist" element={<EmployeeList />} />
          <Route path="/accessorieslist" element={<AccessoriesList />} />
          <Route path="/servicerequest" element={<ServiceRequest />} />
          <Route path="/servicerequest/:id" element={<ServiceRequestDetails />} />

          <Route path="/serviceorderhistory" element={<ServiceOrderHistory />} />
          <Route path="/serviceviewhistory/:id" element={<ServiceViewHistory />} />

          <Route path="/access" element={<Access />} />
          <Route path="/access/:accessoryId" element={<AccessoryPage />} />
          <Route path="/productlistadmin" element={<ProductListAdmin />} />
          <Route path="/productlistadmin/:productId" element={<ProductPage />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/serviceorderhistory" element={<ServiceOrderHistory />} />

        </Routes>
      </main>
    </div>
  );
}

export default App;
