// src/components/ServiceRequest/ServiceRequest.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import Navbar from '../Navbar/Navbar';
import '../../styles/ServiceOrderHistory.css';
import UserSearchDropdown from '../UserSearchDropdown/UserSearchDropdown';
import ProductSearchDropdown from '../ProductSearchDropdown/ProductSearchDropdown';
import TechnicianSearchDropdown from '../TechnicianSearchDropdown/TechnicianSearchDropdown';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import 'react-toastify/dist/ReactToastify.css';

const ServiceOrderHistory = ({ showNavbar = true }) => {
  const [serviceRequests, setServiceRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [productId, setProductId] = useState(null);
  const [productName, setProductName] = useState(null);
  const [category, setCategory] = useState('ALL');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const issueLabels = {
    issue1: 'Repair needed',
    issue2: 'Sound coming from the device',
    issue3: 'Screen flickering',
    issue4: 'Battery not charging',
  };

  const [createFormData, setCreateFormData] = useState({
    customerId: null,
    productId: null,
    technicianId: null,
    accessoriesId: null,
    issueDescription: {
      issues: {
        issue1: false,
        issue2: false,
        issue3: false,
        issue4: false
      },
      description: ''
    },
    status: 'PENDING'
  });

  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    page: 1,
    size: 10,
    totalElements: 0,
    totalPages: 1,
  });
  // Handle filter change
  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
    // Reset selections when category changes
    setSelectedCustomer(null);
    setSelectedTechnician(null);
  };
  // Apply filter button click handler
  const handleApplyFilter = () => {
    fetchServiceRequests(pagination.page, category, selectedCustomer, selectedTechnician);
  };

  // Fetch service requests on component mount and when filters change
  useEffect(() => {
    fetchServiceRequests(pagination.page, category, selectedCustomer, selectedTechnician);
  }, [pagination.page, category, selectedCustomer, selectedTechnician]);

  // Other handlers (search, modal actions, etc.)
  const handleChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value);
  };

  // Handle user selection
  const handleCustomerSelection = (user) => {
    setSelectedCustomer(user);
  };

  const handleTechnicianSelection = (technician) => {
    setSelectedTechnician(technician);
  };

  // Handlers for user and product selection
  const handleUserSelection = (user) => {
    setUserId(user.id);
    setUserName(user.name);
  };

  const handleProductSelection = (product) => {
    setProductId(product.id);
    setProductName(product.name);
  };


  useEffect(() => {
    if (userId) {
      setCreateFormData((prevData) => ({
        ...prevData,
        customerId: userId
      }));
    }
  }, [userId]);

  useEffect(() => {
    if (productId) {
      setCreateFormData((prevData) => ({
        ...prevData,
        productId: productId
      }));
    }
  }, [productId]);

  // Fetch service requests from API
  useEffect(() => {
    fetchServiceRequests(pagination.page, category);
  }, [pagination.page, category]);

  const fetchServiceRequests = async (page = 1, category = 'ALL', selectedCustomer = null, selectedTechnician = null) => {
    try {
      const accessToken = localStorage.getItem('token');
      let url;
      let headers = {
        'Content-Type': 'application/json',
      };

      if (accessToken) {
        if (category === 'TECHNICIAN') {
          if (selectedTechnician) {
            url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.SERVICEREQUEST_URL}/technician/${selectedTechnician.id}/list?status=ALL&page=${page}`;
          } else {
            // When the technician is not selected, list all requests
            url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.SERVICEREQUEST_URL}/list?status=ALL&page=${page}`;
          }
        } else if (category === 'CUSTOMER') {
          if (selectedCustomer) {
            url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.SERVICEREQUEST_URL}/customer/${selectedCustomer.id}/list?status=ALL&page=${page}`;
          } else {
            // When the customer is not selected, list all requests
            url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.SERVICEREQUEST_URL}/list?status=ALL&page=${page}`;
          }
        } else {
          // Default behavior for 'ALL' category
          url = `${ApiEndpoints.BASE_URL}${ApiEndpoints.SERVICEREQUEST_URL}/list?status=Completed&page=${page}`;
        }
        headers.Authorization = `Bearer ${accessToken}`;
      }

      const response = await axios.get(url, { headers });

      setServiceRequests(response.data.content);
      setFilteredRequests(response.data.content);
      setPagination({
        hasNextPage: response.data.hasNextPage,
        hasPreviousPage: response.data.hasPreviousPage,
        page: response.data.page,
        size: response.data.size,
        totalElements: response.data.totalElements,
        totalPages: response.data.totalPages,
      });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching service requests:', error);
      setError('Error fetching service requests. Please try again later.');
      setLoading(false);
    }
  };


  const handlePageChange = (event, newPage) => {
    setPagination((prev) => ({
      ...prev,
      page: newPage,
    }));
  };
  // Format date to a readable string
  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {showNavbar && <Navbar />}
      <div className="service-request-list">
        <div className="search-bar-request9">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleChangeSearchTerm}
            className="search-input109"
          />
          <div className="heading9">
            <h1>ServiceOrderHistory</h1>
          </div>
        </div>

        <div className="filter-search-container">
          {/* Category Filters */}
          <div className="filter-container1">
            <span
              className={`filter-option1 ${category === 'ALL' ? 'active' : ''}`}
              onClick={() => handleCategoryChange('ALL')}
            >
              All
            </span>
            <span
              className={`filter-option1 ${category === 'CUSTOMER' ? 'active' : ''}`}
              onClick={() => handleCategoryChange('CUSTOMER')}
            >
              Customer
            </span>
            <span
              className={`filter-option1 ${category === 'TECHNICIAN' ? 'active' : ''}`}
              onClick={() => handleCategoryChange('TECHNICIAN')}
            >
              Technician
            </span>
          </div>

          {category === 'CUSTOMER' && (
            <div className="search-bar-wrapper">
              <UserSearchDropdown
                onUserSelect={handleCustomerSelection}
                userName={selectedCustomer?.name || ''}
              />
            </div>
          )}

          {category === 'TECHNICIAN' && (
            <div className="search-bar-wrapper">
              <TechnicianSearchDropdown
                onTechnicianSelect={handleTechnicianSelection}
                technicianName={selectedTechnician?.name || ''}
              />
            </div>
          )}

          {/* Apply Filter Button */}
          {(category === 'CUSTOMER' || category === 'TECHNICIAN') && (
            <button className="apply-filter-button" onClick={handleApplyFilter}>
              Apply Filter
            </button>
          )}
        </div>

        <div className="table-responsive">
  <table className="service-request-table">
    <thead>
      <tr>
        <th className="sno">S.No</th>
        <th className="id">ID</th>
        <th className="date">Date</th>
        <th className="customer">Customer Name</th>
        <th className="product">Product Name</th>
        <th className="status">Status</th>
        <th className="action">Actions</th>
      </tr>
    </thead>
    <tbody>
      {filteredRequests.map((request, index) => {
        const page = pagination.page || 1; // Default to page 1 if undefined
        const itemsPerPage = pagination.itemsPerPage || 10; // Default to 10 items per page if undefined
        const serialNumber = (page - 1) * itemsPerPage + index + 1;

        return (
          <tr key={request.id}>
            <td className="sno">{serialNumber}</td>
            <td className="id">{request.id || 'N/A'}</td>
            <td className="date">{formatDate(request.createdAt) || 'N/A'}</td>
            <td className="customer">{request.customer?.name || 'N/A'}</td>
            <td className="product">{request.product?.name || 'N/A'}</td>
            <td className="status">{request.status || 'N/A'}</td>
            <td className="action">
              <Link to={`/serviceviewhistory/${request.id}`} className="view-button">
                View
              </Link>
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
</div>


        {/* Pagination */}
        <div className="pagination">
          <Stack spacing={2}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.page}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: () => <span>Previous</span>,
                    next: () => <span>Next</span>
                  }}
                  {...item}
                />
              )}
            />
          </Stack>
        </div>

        {/* Add Service Request Modal */}
        {showAddModal && (
          <div className="modal">
            <div className="modal-content">
              <div className="form-group-service">
                <label htmlFor="customer-name">Customer Name:</label>
                <UserSearchDropdown
                  onUserSelect={handleUserSelection}
                  userName={selectedCustomer?.name || ''}
                />
              </div>

              <div className="form-group-service">
                <label htmlFor="product-name">Product Name:</label>
                <ProductSearchDropdown
                  onProductSelect={handleProductSelection}
                  productName={createFormData.productName || ''}
                />
              </div>

              <div className="issue-description">
                <div className="form-group-service">
                  <label htmlFor="issues">Issues:</label>
                  <div className="issue-list">
                    {['issue1', 'issue2', 'issue3', 'issue4'].map((issueId) => (
                      <div className="issue-item" key={issueId}>
                        <input
                          type="checkbox"
                          id={issueId}
                          checked={createFormData.issueDescription.issues[issueId] || false}
                          onChange={(e) => setCreateFormData({
                            ...createFormData,
                            issueDescription: {
                              ...createFormData.issueDescription,
                              issues: {
                                ...createFormData.issueDescription.issues,
                                [issueId]: e.target.checked
                              }
                            }
                          })}
                        />
                        <label htmlFor={issueId}>{issueLabels[issueId]}</label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="form-group-service">
                  <label htmlFor="issue-description">Issue Description:</label>
                  <textarea
                    value={createFormData.issueDescription.description || ''}
                    onChange={(event) =>
                      setCreateFormData({
                        ...createFormData,
                        issueDescription: {
                          ...createFormData.issueDescription,
                          description: event.target.value
                        }
                      })
                    }
                    placeholder="Enter issue description here..."
                    className="textarea-container"
                    rows="10"
                  />
                </div>

                <div className="form-group-service">
                  <label htmlFor="status">Status</label>
                  <select
                    value={createFormData.status}
                    onChange={(e) => setCreateFormData({ ...createFormData, status: e.target.value })}
                  >
                    <option>Pending</option>
                    <option>Assigned</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default ServiceOrderHistory;
