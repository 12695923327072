class ApiEndpoints {
    static BASE_URL = 'https://powers.admirosoft.com';
    static LOGIN_URL = '/api/v1/auth/authenticate';
    static REFRESH_URL = '/api/v1/auth/refresh-token';
    static LOGOUT_URL = '/api/v1/auth/logout';

    static PRODUCTLIST_URL = '/api/v1/product';
    static PRODUCTID_URL = '/api/v1/product/';
    static CREATE_PRODUCT_URL = '/api/v1/product';
    static DELETE_IMAGE_URL = '/files/delete';
    static ACCESSORIESLIST_URL = '/api/v1/accessories';
    static SERVICEREQUEST_URL = '/api/v1/service-request';
    static USER_TYPE = '/api/v1/user';
    static BASEURL_IMAGE = 'https://assets.admiro.buzz/';
    static PRODUCT_THUMBNAIL_UPLOAD_URL = '/files/upload/product';
    // static PROFILE_URL = '/api/v1/user/profile';
    static TECHNICIAN_INVENTORY = '/api/v1/technician-inventory';
    static POS_URL = '/api/v1/user-items';
  }
  export default ApiEndpoints;
  