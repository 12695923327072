import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UserSearchDropdown from '../UserSearchDropdown/UserSearchDropdown';
import ProductSearchDropdown from '../ProductSearchDropdown/ProductSearchDropdown';
import AccessorySearchDropdown from '../AccessorySearchDropdown/AccessorySearchDropdown';
import '../../styles/POSComponent.css';
import Navbar from '../Navbar/Navbar';
import { toast } from 'react-toastify';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';


const POSComponent = () => {
  const [addData, setAddData] = useState({
    productName: '',
    selectedProduct: null,
    selectedUser: null,
    customerName: '',
    selectedAccessory: null,
    accessoryName: '',
    quantity: '',
    installationDate: '',
    warrantyExpiry: '',
    requiresInstallation: false, // New field to track if installation is required

  });

  const [editData, setEditData] = useState({
    productName: '',
    selectedProduct: null,
    selectedUser: null,
    customerName: '',
    selectedAccessory: null,
    accessoryName: '',
    quantity: '',
    installationDate: '',
    warrantyExpiry: '',
    requiresInstallation: false,
  });

  const [cartItems, setCartItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('product');

  useEffect(() => {
    const fetchCartItems = async () => {
      if (!addData.selectedUser) return;

      try {
        const access_token = localStorage.getItem('token');
        if (!access_token) {
          throw new Error('No token found');
        }

        const response = await axios.get(
          `${ApiEndpoints.BASE_URL}${ApiEndpoints.POS_URL}/${addData.selectedUser.id}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`
            }
          }
        );

        if (response.status === 200) {
          console.log('Cart Items Response:', response.data);
          if (Array.isArray(response.data)) {
            setCartItems(response.data);
          } else {
            console.error('Response data is not an array:', response.data);
            setCartItems([]);
          }
        } else {
          console.error('Failed to fetch cart items:', response.data);
        }
      } catch (error) {
        console.error('Error fetching cart items:', error);
        setCartItems([]);
      }
    };


    fetchCartItems();
  }, [addData.selectedUser]);

  const handleProductSelection = (product) => {
    setAddData((prevData) => ({
      ...prevData,
      selectedProduct: product,
      productName: product?.name || '',
      selectedAccessory: null,
      accessoryName: '',
    }));
  };

  const handleUserSelection = (user) => {
    setAddData((prevData) => ({
      ...prevData,
      selectedUser: user,
      customerName: user?.name || ''
    }));
  };

  const handleAccessorySelection = (accessory) => {
    setAddData((prevData) => ({
      ...prevData,
      selectedAccessory: accessory,
      accessoryName: accessory?.name || '',
      selectedProduct: null,
      productName: '',
    }));
  };

  const handleOpenModal = () => setShowModal(true);

  const handleCloseModal = () => {
    setShowModal(false);
    setAddData({
      productName: '',
      selectedProduct: null,
      selectedUser: null,
      customerName: '',
      selectedAccessory: null,
      accessoryName: '',
      quantity: '',
      requiresInstallation: false,

    });
    setEditData({
      productName: '',
      selectedProduct: null,
      selectedUser: null,
      customerName: '',
      selectedAccessory: null,
      accessoryName: '',
      quantity: '',
      requiresInstallation: false,

    });
  };

  const handleAddToCart = async (event) => {
    event.preventDefault();

    if ((!addData.selectedProduct && !addData.selectedAccessory) ||
      (addData.selectedProduct && addData.selectedAccessory)) {
      setAddData((prevData) => ({
        ...prevData,
        error: 'Please select either a product or an accessory, but not both.'
      }));
      return;
    }

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      const item = {
        productId: addData.selectedProduct ? addData.selectedProduct.id : null,
        userId: addData.selectedUser?.id || null,
        accessoryId: addData.selectedAccessory ? addData.selectedAccessory.id : null,
        quantity: addData.quantity,
        productName: addData.selectedProduct ? addData.selectedProduct.name : addData.selectedAccessory.name,
        userName: addData.selectedUser ? addData.selectedUser.name : '',
        installationDate: addData.installationDate ? formatToUTC(addData.installationDate) : '',
        warrantyExpiry: addData.warrantyExpiry ? formatToUTC(addData.warrantyExpiry) : '',
        requiresInstallation: addData.requiresInstallation,

      };

      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.POS_URL}`,
        item,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        setCartItems((prevItems) => [...prevItems, item]);
        toast.success('Product added to cart successfully!');
        handleCloseModal();
      } else {
        setAddData((prevData) => ({
          ...prevData,
          error: 'Failed to add product to cart.'
        }));
        console.error('Failed to add product to cart:', response.data);
      }
    } catch (error) {
      setAddData((prevData) => ({
        ...prevData,
        error: 'Error adding product to cart. Please try again later.'
      }));
      console.error('Error:', error);
    }
  };

  const handleInstallationChange = (e) => {
    const isRequired = e.target.value === 'yes';
    setAddData((prevData) => ({
      ...prevData,
      installationRequired: isRequired,
      installationDate: isRequired ? prevData.installationDate : '',
      warrantyExpiry: isRequired ? prevData.warrantyExpiry : '',
    }));
  };


  const formatToUTC = (localDateTime) => {
    const date = new Date(localDateTime);
    return date.toISOString();
  };

  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  return (
    <div>
      <Navbar />
      <div className="pos-component-container">
        {showModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h2>Add</h2>
              {(addData.error || editData.error) && <div className="error-message">{addData.error || editData.error}</div>}
              <form onSubmit={handleAddToCart}>
                <div className="form-group">
                  <label>
                    <input
                      type="radio"
                      name="searchType"
                      value="product"
                      checked={searchType === 'product'}
                      onChange={() => setSearchType('product')}
                    />
                    Product
                  </label>

                  <label>
                    <input
                      type="radio"
                      name="searchType"
                      value="accessory"
                      checked={searchType === 'accessory'}
                      onChange={() => setSearchType('accessory')}
                    />
                    Accessory
                  </label>
                </div>

                {searchType === 'product' && (
                  <div className="form-group">
                    <label htmlFor="name">Product Name:</label>
                    <ProductSearchDropdown
                      style={{ borderRadius: '0' }}
                      onProductSelect={(product) => handleProductSelection(product)}
                      productName={addData.productName}
                    />
                  </div>
                )}

                {searchType === 'accessory' && (
                  <div className="form-group">
                    <label htmlFor="name">Accessory Name:</label>
                    <AccessorySearchDropdown
                      style={{ borderRadius: '0' }}
                      onAccessorySelect={(accessory) => handleAccessorySelection(accessory)}
                      accessoryName={addData.accessoryName}
                    />
                  </div>
                )}

                <div className="form-group">
                  <label htmlFor="name">Customer Name:</label>
                  <UserSearchDropdown
                    style={{ borderRadius: '0' }}
                    onUserSelect={(user) => handleUserSelection(user)}
                    customerName={addData.customerName}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="quantity">Quantity:</label>
                  <input
                    id="quantity"
                    type="number"
                    value={addData.quantity}
                    onChange={(e) => setAddData((prevData) => ({
                      ...prevData,
                      quantity: parseInt(e.target.value, 10)
                    }))}
                    min="1"
                  />
                </div>

                <div className="form-group">
                  <label>Installation Required:</label>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <label style={{ marginRight: '10px' }}>
                        <input
                          type="radio"
                          name="installationRequired"
                          value="yes"
                          checked={addData.installationRequired === true}
                          onChange={handleInstallationChange}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="installationRequired"
                          value="no"
                          checked={addData.installationRequired === false}
                          onChange={handleInstallationChange}
                        />
                        No
                      </label>
                    </div>

                    {addData.installationRequired && (
                      <div style={{ marginTop: '10px' }}>
                        <label htmlFor="installationDate"></label>
                        <input
                          id="installationDate"
                          type="datetime-local"
                          value={addData.installationDate ? addData.installationDate.slice(0, 16) : ''}
                          onChange={(e) =>
                            setAddData((prevData) => ({
                              ...prevData,
                              installationDate: formatToUTC(e.target.value),
                            }))
                          }
                        />

                        <input
                          id="installationDate"
                          type="text"
                          placeholder="HH:MM:SS"
                          value={addData.installationDate ? addData.installationDate.slice(11, 19) : ''}
                          onChange={(e) => {
                            const time = e.target.value;
                            setAddData((prevData) => ({
                              ...prevData,
                              installationDate: formatToUTC(addData.installationDate.slice(0, 11) + time)
                            }));
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label>Warranty:</label>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        id="warrantyExpiry"
                        type="datetime-local"
                        value={addData.warrantyExpiry ? addData.warrantyExpiry.slice(0, 16) : ''}
                        onChange={(e) => setAddData((prevData) => ({
                          ...prevData,
                          warrantyExpiry: formatToUTC(e.target.value)
                        }))}
                      />
                      <input
                        id="warrantyTime"
                        type="text"
                        placeholder="HH:MM:SS"
                        value={addData.warrantyExpiry ? addData.warrantyExpiry.slice(11, 19) : ''}
                        onChange={(e) => {
                          const time = e.target.value;
                          setAddData((prevData) => ({
                            ...prevData,
                            warrantyExpiry: formatToUTC(addData.warrantyExpiry.slice(0, 11) + time)
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="button-container">
                <button type="button" onClick={handleCloseModal} className="cancel-buttonPOS">Cancel</button>
                  <button type="submit" className="submit-buttonPOS">Add POS</button>
                </div>
              </form>
            </div>
          </div>
        )}


        <div className="product-list-content">
          <div className="actions-bar">
            <input
              type="text"
              placeholder="Search products..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input10"
            />
            <h1 className="list-heading">Point Of Sale</h1>
            <button className="add-buttonPOS" onClick={handleOpenModal}>
              Add
            </button>
          </div>

          <table className="product-table">
            <thead>
              <tr className="table-header">
                <th className="header-cell">Product/Accessory Name</th>
                <th className="header-cell">Customer Name</th>
                <th className="header-cell">Quantity</th>
                <th className='header-cell'>Installation</th>
                <th className='header-cell'>Warranty</th>
              </tr>
            </thead>
            <tbody>
              {(Array.isArray(cartItems) ? cartItems : []).map((item, index) => (
                <tr key={index}>
                  <td className="data-cell">{item.productName}</td>
                  <td className="data-cell">{item.userName}</td>
                  <td className="data-cell">{item.quantity}</td>
                  <td className="data-cell">{new Date(item.installationDate).toLocaleString()}</td>
                  <td className="data-cell">{new Date(item.warrantyExpiry).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default POSComponent;
