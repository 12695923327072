import axios from 'axios';
import ApiEndpoints from '../ApiEndpoints/ApiEndpoints';

export const login = async (email, password) => {
  try {
    const response = await axios.post(
      `${ApiEndpoints.BASE_URL}${ApiEndpoints.LOGIN_URL}`,
      { email, password,platform: "WEB" },
      { headers: { 'Content-Type': 'application/json' } }
    );
    const { access_token, refresh_token,user_id } = response.data;
    localStorage.setItem('token', access_token);
    localStorage.setItem('refreshToken', refresh_token);
    localStorage.setItem('userid', user_id);
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const refreshToken = async (old_refresh_token) => {
  try {
    const response = await axios.post(
      `${ApiEndpoints.BASE_URL}${ApiEndpoints.REFRESH_URL}`,
      {},
      { headers: { Authorization: `Bearer ${old_refresh_token}`, 'Content-Type': 'application/json' } }
    );
    const { access_token, refresh_token,user_id } = response.data;
    localStorage.setItem('token', access_token);
    localStorage.setItem('refreshToken', refresh_token);
    localStorage.setItem('userid', user_id);

    return { access_token, refresh_token};
  } catch (error) {
    console.error('Refresh token error:', error);
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
      console.error('Error response headers:', error.response.headers);
    }
    throw error;
  }
};

export const logout = async () => {
  try {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userid');

    await axios.post(
      `${ApiEndpoints.BASE_URL}${ApiEndpoints.LOGOUT_URL}`,
      {},
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (error) {
    console.error('Logout error:', error);
    throw error;
  }
};
